import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)` 
  width: 24px; 
  height: 24px;
`;

const VolumeSVG = (obj: any) => (
  <Svg viewBox="0 0 24 24" className={obj.className}>
    <path
      fill="currentColor"
      d="M3 9v6h4l5 5V4L7 9H3zm7-.17v6.34L7.83 13H5v-2h2.83L10 8.83zM16.5 12c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77 0-4.28-2.99-7.86-7-8.77z"
    />
  </Svg>
);

export default VolumeSVG;
