import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import {
  currentTrack,
  activePlaylist,
  playing,
  redditOptionsForActivePlaylist,
  seekTo,
} from '../RecoilAtoms/Transport';
import { accountName } from '../RecoilAtoms/Auth';

export default function usePlaylistControllerUser(
  playlistName: string,
  redditApi: any,
) {
  const accountNameState: string = useRecoilValue(accountName);
  const [localPlaylistState, setLocalPlaylistState] = useState([]);
  const [highlightState, setHightlightState] = useState(-1);
  const [warning, setWarning] = useState('');
  const [localSearchIDState, setLocalSearchID] = useState('');

  // recoil state
  const setCurrentTrack = useSetRecoilState(currentTrack);
  const setSeekTo = useSetRecoilState(seekTo);
  const [activePlaylistState, setActivePlaylist] = useRecoilState(activePlaylist);
  const [playingState, setPlaying] = useRecoilState(playing);
  const [
    redditOptionsForActivePlaylistState,
    setRedditOptionsForActivePlaylist,
  ] = useRecoilState(redditOptionsForActivePlaylist);

  const addId = (arr: any) => arr.map((obj: any, index: any) => ({ ...obj, id: index }));

  const fetchRedditdata = async (rOptions: any, playlistState: any) => {
    const [playlist, lastTrackID] = await redditApi.getUpvoted(rOptions);
    if (!playlist) {
      setWarning('No more music');
      return;
    }

    setLocalSearchID(lastTrackID);
    const clone = [...playlistState];
    clone.push(...playlist);
    const final = addId(clone);
    setLocalPlaylistState(final);

    if (redditOptionsForActivePlaylistState.subReddit === playlistName) {
      setActivePlaylist(final);
      const cloneOptions = { ...redditOptionsForActivePlaylistState };
      cloneOptions.after = localSearchIDState;
      setRedditOptionsForActivePlaylist(cloneOptions);
    }
  };

  useEffect(() => {
    if (accountNameState) {
      fetchRedditdata({}, localPlaylistState);
    }
  }, [
    accountNameState,
  ]);

  // If activeplaylist updates in the global space, this will trigger
  // the local view to update as well.
  useEffect(() => {
    if (redditOptionsForActivePlaylistState.subReddit === playlistName) {
      setLocalPlaylistState(activePlaylistState);
    }
  }, [
    activePlaylistState,
  ]);

  // Handles the doubleclick
  const doubleClick = (n: number) => {
    if (redditOptionsForActivePlaylistState.subReddit !== playlistName) {
      setActivePlaylist(localPlaylistState);
      const clone: any = { ...redditOptionsForActivePlaylistState };
      clone.subReddit = playlistName;
      clone.type = null;
      clone.dateFilter = null;
      clone.after = localSearchIDState;
      setRedditOptionsForActivePlaylist(clone);
    }
    setSeekTo(0);
    setCurrentTrack(n);
    if (!playingState) {
      setPlaying(true);
    }
  };

  const moreMusicButton = () => fetchRedditdata(
    {
      subReddit: playlistName,
      limit: 100,
      before: '',
      after: localSearchIDState,
      type: null,
      dateFilter: null,
    },
    localPlaylistState,
  );

  return [
    warning,
    localPlaylistState,
    highlightState,
    setHightlightState,
    moreMusicButton,
    doubleClick,
  ] as const;
}
