import { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Helmet } from 'react-helmet';
import useSearchController from '../../Controllers/SearchController';
import Routes from '../../Routes/Routes';
import Colours from '../../Colours/Colours';
import { recentlySelected } from '../../RecoilAtoms/Transport';
import SearchSVG from '../../Icons/SearchSVG';
import numberWithCommas from '../../Helpers/NumbersWithCommas';
import createLinkToPlaylist from '../../Helpers/CreateLinkToPlaylist';

const SearchElement: any = styled(SearchSVG)`   
  height: 35px;
  width: auto;
  padding: 8px 0px 5px 15px;
  flex: 1;
  max-width: 35px;
`;

const SearchField = styled.input`
  font-size: 24px;
  padding: 5px 0px 3px 10px;
  border-radius: 30px;
  background-color: ${Colours('white')};
  border-width:0px;
  border:none;
  width: 70%;
  flex: 5;
  :focus {
      outline: none;
  }
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px 10px 0px;
  background-color: ${Colours('white')};
  width: 98%;
  border-radius: 30px;
  display: flex;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px 10px 0px;
`;

const RadioButton: any = styled.button`
  color: ${Colours('white')};
  background-color: ${Colours('dark1')};
  transition: 0.3s;
  :hover {
    cursor: pointer;
    background: ${Colours('purple')};
  }
  ${(props: any) => {
    if (props.highlight) {
      return `
        background-color: ${Colours('green')};;
        :hover {
          cursor: pointer;
          background: ${Colours('green')};
        }
      `;
    }
    return '';
  }}
  border: 1px solid ${Colours('white')}; 
  font-size: 19px;
  padding: 10px 30px 10px 30px;
  margin: 30px 0px 30px 0px;
  border-radius: 30px;
  box-shadow: 0px; 

  margin: 0px 10px 20px 0px;
`;

const Page = styled.div`
  padding: 50px 20px 20px 20px;
`;

const Header = styled.div`
  color: ${Colours('white')};
  font-size: 24px;
  padding: 20px 0px 10px 0px;
  font-weight: bold;
`;

const Container = styled.div`
  margin: 10px;
  padding: 10px;
  border: 1px solid ${Colours('offWhite')};
  transition: 0.3s;
  width: 130px;
  :hover {
    background-color: ${Colours('grey')};
  }
  cursor: pointer;
`;

const ImageContainer = styled.img``;

const BlankSquare = styled.div`
  background-color: ${Colours('purple')};
  width: 130px;
  height: 130px;
  margin: 0px 0px 10px 0px;
`;

const ListingHeader = styled.div`
  font-size: 19px;
  text-decoration: none;
  color: ${Colours('offWhite')};
  font-weight: bold;
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListingBody = styled.div`
  margin: 10px 0px 10px 0px;
  width: 130px;
  font-size: 19px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colours('offWhite')}
`;

const Listings = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function SearchDesktopView(props: any) {
  const [
    searchField,
    setSearchField,
    searchResults,
  ] = useSearchController(
    props.redditApi,
    props.location,
    props.history,
  );
  const [recentlySelectedState, setRecentlySelected] = useRecoilState(recentlySelected);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('recentlySelected') || '[]');
    setRecentlySelected(data);
  }, []);

  const searchResultsRender = (list: any[]) => list.map((item) => <Link to={`${Routes('playlist')}/${item.name}/?type=hot`}>
      <Container>
       {
         item?.icon_img
         && <ImageContainer
         src={item?.icon_img}
         width={130}
         />
       }
       {
         !item?.icon_img
         && <BlankSquare />
       }
       <ListingHeader> {item.name} </ListingHeader>
       <ListingBody>
         {numberWithCommas(parseInt(item?.subscriber_count, 10))} subscribers
        </ListingBody>
       <ListingBody>
         {numberWithCommas(parseInt(item?.active_user_count, 10))} active users
       </ListingBody>
     </Container>
   </Link>);

  const recentlySelectedNavBar = (list: any[]) => list.map((row) => {
    const path : string = createLinkToPlaylist(row)!;
    return <Link to={path}>
        <RadioButton>
          {row.subReddit} | {row.type} {(row.dateFilter) ? `| ${row.dateFilter}` : ''}
        </RadioButton>
    </Link>;
  });

  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search | Playlister for Reddit</title>
      </Helmet>
      <Form onSubmit={(event: any) => { event.preventDefault(); }}>
        <SearchElement/>
        <SearchField
          type="text"
          name="search"
          value={String(searchField)}
          onChange={(e) => setSearchField(e.target.value)}
          placeholder="Search for subreddits"
        />
      </Form>
      {
        recentlySelectedState.length
        && <Header>Recently selected</Header>
      }
      <ButtonRow>
        {recentlySelectedNavBar(recentlySelectedState.slice(0).reverse())}
      </ButtonRow>
      {
        searchResults
        && <Listings>
          {searchResultsRender(searchResults)}
        </Listings>
      }
    </Page>
  );
}

export default SearchDesktopView;
