import { atom } from 'recoil';

export const signedIn = atom({
  key: 'signedIn',
  default: false,
});

export const accountName = atom({
  key: 'accountName',
  default: '',
});
