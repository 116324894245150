import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Colours from '../../Colours/Colours';

const Page = styled.div`
  padding: 50px 20px 20px 20px;
`;

const Header = styled.div`
  color: ${Colours('white')};
  font-size: 48px;
  padding: 10px 0px 10px 0px;
`;

const Text = styled.div`
  color: ${Colours('white')};
  font-size: 19px;
  padding: 10px 10px 20px 5px;
`;

const SignOut = styled.button`
  color: ${Colours('white')};
  background-color: ${Colours('dark1')};
  border: 3px solid ${Colours('offWhite')};  font-size: 19px;
  padding: 10px 30px 10px 30px;
  border-radius: 30px;
  box-shadow: 0px; 
  :hover {
    cursor: pointer;
    background: ${Colours('dark2')};
  }
`;

function Home(props: any) {
  const { redditApi } = props;
  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Account | Playlister for Reddit</title>
      </Helmet>
      <Header>Account details</Header>
      <Text>
        {
          redditApi.checkIfSignedIn()
            ? `Signed in as ${props.redditApi.accountName}` : 'Connect to Reddit to use additional features'
        }
      </Text>
      {
        redditApi.checkIfSignedIn()
        && <SignOut onClick={(e) => props.signOut(e)}>Sign out</SignOut>
      }
      {
        !redditApi.checkIfSignedIn()
        && <a href={redditApi.getTokenURL()}>
          <SignOut onClick={(e) => props.signOut(e)}>Connect</SignOut>
        </a>
      }
    </Page>
  );
}

export default Home;
