const Subreddits: any = {
  'Electronic music': [
    {
      name: 'acidhouse',
    },
    {
      name: 'ambientmusic',
    },
    {
      name: 'AStateOfTrance',
    },
    {
      name: 'AtmosphericDnB',
    },
    {
      name: 'BigBeat',
    },
    {
      name: 'boogiemusic',
    },
    {
      name: 'breakbeat',
    },
    {
      name: 'breakcore',
    },
    {
      name: 'chicagohouse',
    },
    {
      name: 'chillout',
    },
    {
      name: 'Chipbreak',
    },
    {
      name: 'Chiptunes',
    },
    {
      name: 'complextro',
    },
    {
      name: 'cxd',
    },
    {
      name: 'darkstep',
    },
    {
      name: 'deephouse',
    },
    {
      name: 'DnB',
    },
    {
      name: 'DubStep',
    },
    {
      name: 'EDM',
    },
    {
      name: 'EBM',
    },
    {
      name: 'electronicdancemusic',
    },
    {
      name: 'ElectronicJazz',
    },
    {
      name: 'ElectronicBlues',
    },
    {
      name: 'electrohiphop',
    },
    {
      name: 'electrohouse',
    },
    {
      name: 'electronicmagic',
    },
    {
      name: 'ElectronicMusic',
    },
    {
      name: 'electropop',
    },
    {
      name: 'electroswing',
    },
    {
      name: 'ExperimentalMusic',
    },
    {
      name: 'fidget',
    },
    {
      name: 'filth',
    },
    {
      name: 'frenchelectro',
    },
    {
      name: 'frenchhouse',
    },
    {
      name: 'funkhouse',
    },
    {
      name: 'futurebeats',
    },
    {
      name: 'FutureGarage',
    },
    {
      name: 'futuresynth',
    },
    {
      name: 'gabber',
    },
    {
      name: 'glitch',
    },
    {
      name: 'glitchop',
    },
    {
      name: 'Grime',
    },
    {
      name: 'happyhardcore',
    },
    {
      name: 'hardhouse',
    },
    {
      name: 'hardstyle',
    },
    {
      name: 'house',
    },
    {
      name: 'idm',
    },
    {
      name: 'industrialmusic',
    },
    {
      name: 'ItaloDisco',
    },
    {
      name: 'latinhouse',
    },
    {
      name: 'LiquidDubstep',
    },
    {
      name: 'mashups',
    },
    {
      name: 'melodichouse',
    },
    {
      name: 'minimal',
    },
    {
      name: 'mixes',
    },
    {
      name: 'moombahcore',
    },
    {
      name: 'nightstep',
    },
    {
      name: 'OldskoolRave',
    },
    {
      name: 'Outrun',
    },
    {
      name: 'theOverload',
    },
    {
      name: 'partymusic',
    },
    {
      name: 'plunderphonics',
    },
    {
      name: 'psybient',
    },
    {
      name: 'PsyBreaks',
    },
    {
      name: 'psytrance',
    },
    {
      name: 'purplemusic',
    },
    {
      name: 'raggajungle',
    },
    {
      name: 'RealDubstep',
    },
    {
      name: 'skweee',
    },
    {
      name: 'swinghouse',
    },
    {
      name: 'tech_house',
    },
    {
      name: 'Techno',
    },
    {
      name: 'Trance',
    },
    {
      name: 'tranceandbass',
    },
    {
      name: 'trap',
    },
    {
      name: 'tribalbeats',
    },
    {
      name: 'TropicalHouse',
    },
    {
      name: 'ukfunky',
    },
    {
      name: 'witch_house',
    },
    {
      name: 'wuuB',
    },
  ],
  'Rock and metal': [
    {
      name: '80sHardcorePunk',
    },
    {
      name: '90sAlternative',
    },
    {
      name: '90sPunk',
    },
    {
      name: '90sRock',
    },
    {
      name: 'AlternativeRock',
    },
    {
      name: 'AltCountry',
    },
    {
      name: 'AORMelodic',
    },
    {
      name: 'ausmetal',
    },
    {
      name: 'BlackMetal',
    },
    {
      name: 'bluegrass',
    },
    {
      name: 'Blues',
    },
    {
      name: 'bluesrock',
    },
    {
      name: 'Boneyard',
    },
    {
      name: 'CanadianClassicRock',
    },
    {
      name: 'CanadianMusic',
    },
    {
      name: 'ClassicRock',
    },
    {
      name: 'country',
    },
    {
      name: 'Christcore',
    },
    {
      name: 'crunkcore',
    },
    {
      name: 'deathcore',
    },
    {
      name: 'deathmetal',
    },
    {
      name: 'Djent',
    },
    {
      name: 'DoomMetal',
    },
    {
      name: 'Drone',
    },
    {
      name: 'Emo',
    },
    {
      name: 'EmoScreamo',
    },
    {
      name: 'epicmetal',
    },
    {
      name: 'flocked',
    },
    {
      name: 'folk',
    },
    {
      name: 'folkmetal',
    },
    {
      name: 'folkpunk',
    },
    {
      name: 'folkrock',
    },
    {
      name: 'folkunknown',
    },
    {
      name: 'GaragePunk',
    },
    {
      name: 'GothicMetal',
    },
    {
      name: 'Grunge',
    },
    {
      name: 'hardcore',
    },
    {
      name: 'HardRock',
    },
    {
      name: 'horrorpunk',
    },
    {
      name: 'indie_rock',
    },
    {
      name: 'jrock',
    },
    {
      name: 'krautrock',
    },
    {
      name: 'LadiesofMetal',
    },
    {
      name: 'MathRock',
    },
    {
      name: 'melodicdeathmetal',
    },
    {
      name: 'MelodicMetal',
    },
    {
      name: 'MetalNews',
    },
    {
      name: 'Metalmusic',
    },
    {
      name: 'metal',
    },
    {
      name: 'metalcore',
    },
    {
      name: 'ModernRockMusic',
    },
    {
      name: 'monsterfuzz',
    },
    {
      name: 'neopsychedelia',
    },
    {
      name: 'NewWave',
    },
    {
      name: 'noiserock',
    },
    {
      name: 'numetal',
    },
    {
      name: 'pianorock',
    },
    {
      name: 'poppunkers',
    },
    {
      name: 'PostHardcore',
    },
    {
      name: 'PostRock',
    },
    {
      name: 'powermetal',
    },
    {
      name: 'powerpop',
    },
    {
      name: 'ProgMetal',
    },
    {
      name: 'progrockmusic',
    },
    {
      name: 'PsychedelicRock',
    },
    {
      name: 'punk',
    },
    {
      name: 'Punkskahardcore',
    },
    {
      name: 'Punk_Rock',
    },
    {
      name: 'raprock',
    },
    {
      name: 'Rock',
    },
    {
      name: 'shoegaze',
    },
    {
      name: 'stonerrock',
    },
    {
      name: 'symphonicblackmetal',
    },
    {
      name: 'symphonicmetal',
    },
    {
      name: 'synthrock',
    },
    {
      name: 'ThrowbackCore',
    },
    {
      name: 'truethrash',
    },
    {
      name: 'Truemetal',
    },
    {
      name: 'OutlawCountry',
    },
    {
      name: 'WomenRock',
    },
  ],
  HipHop: [
    {
      name: '80sHipHop',
    },
    {
      name: '90sHipHop',
    },
    {
      name: 'altrap',
    },
    {
      name: 'asianrap',
    },
    {
      name: 'backpacker',
    },
    {
      name: 'backspin',
    },
    {
      name: 'BayRap',
    },
    {
      name: 'ChapHop',
    },
    {
      name: 'ChiefKeef',
    },
    {
      name: 'DrillandBop',
    },
    {
      name: 'Emo_Trap',
    },
    {
      name: 'Gfunk',
    },
    {
      name: 'hiphopheads',
    },
    {
      name: 'hiphopheadsnorthwest',
    },
    {
      name: 'hiphop101',
    },
    {
      name: 'MemphisRap',
    },
    {
      name: 'NYrap',
    },
    {
      name: 'Rap',
    },
    {
      name: 'raprock',
    },
    {
      name: 'rapverses',
    },
    {
      name: 'rhymesandbeats',
    },
    {
      name: 'trapmuzik',
    },
    {
      name: 'ukhiphopheads',
    },
    {
      name: 'undergroundchicago',
    },
    {
      name: 'LofiHipHop',
    },
  ],
  'Classical music': [
    {
      name: 'composer',
    },
    {
      name: 'baroque',
    },
    {
      name: 'classicalmusic',
    },
    {
      name: 'contemporary',
    },
    {
      name: 'concertband',
    },
    {
      name: 'choralmusic',
    },
    {
      name: 'ChamberMusic',
    },
    {
      name: 'EarlyMusic',
    },
    {
      name: 'earlymusicalnotation',
    },
    {
      name: 'ElitistClassical',
    },
    {
      name: 'icm',
    },
    {
      name: 'Opera',
    },
    {
      name: 'orchestra',
    },
  ],
  'Music by decade': [
    {
      name: '2010smusic',
    },
    {
      name: '2000smusic',
    },
    {
      name: '90sMusic',
    },
    {
      name: '80sremixes',
    },
    {
      name: '80sMusic',
    },
    {
      name: '70sMusic',
    },
    {
      name: '60sMusic',
    },
    {
      name: '50sMusic',
    },
  ],
  'Music by country, region, and culture': [
    {
      name: 'AfricanMusic',
    },
    {
      name: 'afrobeat',
    },
    {
      name: 'balkanbrass',
    },
    {
      name: 'balkanmusic',
    },
    {
      name: 'brazilianmusic',
    },
    {
      name: 'britpop',
    },
    {
      name: 'CroatianMusic',
    },
    {
      name: 'Flamenco',
    },
    {
      name: 'international_music',
    },
    {
      name: 'Irishmusic',
    },
    {
      name: 'ItalianMusic',
    },
    {
      name: 'jpop',
    },
    {
      name: 'KoreanRock',
    },
    {
      name: 'kpop',
    },
    {
      name: 'MoscowBeat',
    },
    {
      name: 'RoMusic',
    },
    {
      name: 'spop',
    },
    {
      name: 'somluso',
    },
    {
      name: 'UKbands',
    },
    {
      name: 'WorldMusic',
    },
  ],
  Other: [
    {
      name: '70s',
    },
    {
      name: 'Acappella',
    },
    {
      name: 'AcousticCovers',
    },
    {
      name: 'ambientfolk',
    },
    {
      name: 'animemusic',
    },
    {
      name: 'boomswing',
    },
    {
      name: 'bossanova',
    },
    {
      name: 'carmusic',
    },
    {
      name: 'concerts',
    },
    {
      name: 'chillmusic',
    },
    {
      name: 'chillwave',
    },
    {
      name: 'cpop',
    },
    {
      name: 'Complextro',
    },
    {
      name: 'dancepunk',
    },
    {
      name: 'dembow',
    },
    {
      name: 'disco',
    },
    {
      name: 'DreamPop',
    },
    {
      name: 'dub',
    },
    {
      name: 'Elephant6',
    },
    {
      name: 'ETIMusic',
    },
    {
      name: 'Exotica',
    },
    {
      name: 'FilmMusic',
    },
    {
      name: 'FunkSouMusic',
    },
    {
      name: 'gamemusic',
    },
    {
      name: 'GamesMusicMixMash',
    },
    {
      name: 'GunslingerMusic',
    },
    {
      name: 'GypsyJazz',
    },
    {
      name: 'IndieFolk',
    },
    {
      name: 'jambands',
    },
    {
      name: 'jazz',
    },
    {
      name: 'JazzFusion',
    },
    {
      name: 'JazzInfluence',
    },
    {
      name: 'listentoconcerts',
    },
    {
      name: 'klezmer',
    },
    {
      name: 'lt10k',
    },
    {
      name: 'MedievalMusic',
    },
    {
      name: 'MelancholyMusic',
    },
    {
      name: 'minimalism_music',
    },
    {
      name: 'motown',
    },
    {
      name: 'MusicForConcentration',
    },
    {
      name: 'muzyka',
    },
    {
      name: 'NuDisco',
    },
    {
      name: 'oldiemusic',
    },
    {
      name: 'OldiesMusic',
    },
    {
      name: 'pianocovers',
    },
    {
      name: 'popheads',
    },
    {
      name: 'PoptoRock',
    },
    {
      name: 'QuietStorm',
    },
    {
      name: 'rainymood',
    },
    {
      name: 'recordstorefinds',
    },
    {
      name: 'reggae',
    },
    {
      name: 'remixxd',
    },
    {
      name: 'RetroMusic',
    },
    {
      name: 'rnb',
    },
    {
      name: 'RnBHeads',
    },
    {
      name: 'rootsmusic',
    },
    {
      name: 'SalsaMusic',
    },
    {
      name: 'Ska',
    },
    {
      name: 'Soca',
    },
    {
      name: 'songbooks',
    },
    {
      name: 'Soulies',
    },
    {
      name: 'SoulDivas',
    },
    {
      name: 'SoundsVintage',
    },
    {
      name: 'SpaceMusic',
    },
    {
      name: 'SurfPunk',
    },
    {
      name: 'swing',
    },
    {
      name: 'Tango',
    },
    {
      name: 'TheRealBookVideos',
    },
    {
      name: 'TouhouMusic',
    },
    {
      name: 'TraditionalMusic',
    },
    {
      name: 'treemusic',
    },
    {
      name: 'triphop',
    },
    {
      name: 'vaporwave',
    },
    {
      name: 'VintageObscura',
    },
    {
      name: 'vocaloid',
    },
  ],
  'Any genre': [
    {
      name: 'albumaday',
    },
    {
      name: 'albumoftheday',
    },
    {
      name: 'Albums',
    },
    {
      name: 'albumlisteners',
    },
    {
      name: 'bassheavy',
    },
    {
      name: 'BinauralMusic',
    },
    {
      name: 'BoyBands',
    },
    {
      name: 'Catchysongs',
    },
    {
      name: 'Chopping',
    },
    {
      name: 'CircleMusic',
    },
    {
      name: 'CoverSongs',
    },
    {
      name: 'cyberpunk_music',
    },
    {
      name: 'DANCEPARTY',
    },
    {
      name: 'danktunes',
    },
    {
      name: 'deepcuts',
    },
    {
      name: 'EarlyMusic',
    },
    {
      name: 'earlymusicalnotation',
    },
    {
      name: 'FemaleVocalists',
    },
    {
      name: 'festivals',
    },
    {
      name: 'findaband',
    },
    {
      name: 'FitTunes',
    },
    {
      name: 'FreeAlbums',
    },
    {
      name: 'freemusic',
    },
    {
      name: 'Frisson',
    },
    {
      name: 'gameofbands',
    },
    {
      name: 'GayMusic',
    },
    {
      name: 'germusic',
    },
    {
      name: 'gethightothis',
    },
    {
      name: 'GuiltyPleasureMusic',
    },
    {
      name: 'HeadNodders',
    },
    {
      name: 'heady',
    },
    {
      name: 'HeyThatWasIn',
    },
    {
      name: 'HighFidelity',
    },
    {
      name: 'ifyoulikeblank',
    },
    {
      name: 'ilikethissong',
    },
    {
      name: 'indie',
    },
    {
      name: 'IndieWok',
    },
    {
      name: 'Indieheads',
    },
    {
      name: 'Instrumentals',
    },
    {
      name: 'ipm',
    },
    {
      name: 'IsolatedVocals',
    },
    {
      name: 'LeeHallMusic',
    },
    {
      name: 'LetsTalkMusic',
    },
    {
      name: 'listentoconcerts',
    },
    {
      name: 'listentodynamic',
    },
    {
      name: 'listentomusic',
    },
    {
      name: 'listentonews',
    },
    {
      name: 'ListenToThis',
    },
    {
      name: 'ListenToUs',
    },
    {
      name: 'livemusic',
    },
    {
      name: 'llawenyddhebddiwedd',
    },
    {
      name: 'LongerJams',
    },
    {
      name: 'Lyrics',
    },
    {
      name: 'mainstreammusic',
    },
    {
      name: 'makemeaplaylist',
    },
    {
      name: 'MiddleEasternMusic',
    },
    {
      name: 'MLPtunes',
    },
    {
      name: 'Music',
    },
    {
      name: 'MusicAlbums',
    },
    {
      name: 'musicanova',
    },
    {
      name: 'musicsuggestions',
    },
    {
      name: 'MusicToSleepTo',
    },
    {
      name: 'musicvideos',
    },
    {
      name: 'NameThatSong',
    },
    {
      name: 'NewAlbums',
    },
    {
      name: 'newmusic',
    },
    {
      name: 'onealbumaweek',
    },
    {
      name: 'partymusic',
    },
    {
      name: 'RedditOriginals',
    },
    {
      name: 'RepublicOfMusic',
    },
    {
      name: 'RoyaltyFreeMusic',
    },
    {
      name: 'runningmusic',
    },
    {
      name: 'ScottishMusic',
    },
    {
      name: 'SlavicMusicVideos',
    },
    {
      name: 'songwriterscircle',
    },
    {
      name: 'SpotifyMusic',
    },
    {
      name: 'ThemVoices',
    },
    {
      name: 'TodaysFavoriteSong',
    },
    {
      name: 'unheardof',
    },
    {
      name: 'WhatIListenTo',
    },
    {
      name: 'WTFMusicVideos',
    },
  ],
};

export default Subreddits;
