import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Routes from '../../Routes/Routes';
import useHomeController from '../../Controllers/HomeController';
import Colours from '../../Colours/Colours';
import numberWithCommas from '../../Helpers/NumbersWithCommas';

const Page = styled.div`
  padding: 50px 20px 20px 20px;
`;

const Header = styled.div`
  color: ${Colours('white')};
  font-size: 48px;
  padding: 10px 0px 10px 0px;
`;

const SectionHeader = styled.div`
  color: ${Colours('white')};
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
`;

const Container = styled.div`
  margin: 10px;
  padding: 10px;
  border: 1px solid ${Colours('offWhite')};
  transition: 0.3s;
  width: 130px;
  :hover {
    background-color: ${Colours('grey')};
  }
  cursor: pointer;
  text-decoration:none !important;
  a{
    text-decoration:none;
  }
`;

const Listings = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat( auto-fill, minmax(160px, 1fr));
  max-height: 300px;
  overflow: hidden;
  text-decoration:none;
`;

const ImageContainer = styled.img`
  padding: 0px 0px 10px 0px;
`;

const ListingHeader = styled.div`
  font-size: 19px;
  text-decoration: none;
  color: ${Colours('offWhite')};
  font-weight: bold;
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListingBody = styled.div`
  margin: 10px 0px 10px 0px;
  width: 130px;
  text-decoration: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 19px;
  color: ${Colours('offWhite')}
`;

const BlankSquare = styled.div`
  background-color: ${Colours('purple')};
  width: 130px;
  height: 130px;
  margin: 0px 0px 10px 0px;
`;

const SeperationLine = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${Colours('grey')};
  margin: 10px 0px 20px 0px;
  border-radius: 5px;
`;

function Home(props: any) {
  const redditDataState = useHomeController(props.redditApi);
  const subRedditsJSX = (redditData: any) => {
    if (!redditData) {
      return <div>
        <Listings>
          <SectionHeader>
            An error occured
          </SectionHeader>
        </Listings>
      </div>;
    }

    const result = redditData.map((item: any) => {
      const newItem = item.data.map((x: any) => <Link to={`${Routes('playlist')}/${x.display_name}/?type=hot`} style={{ textDecoration: 'none' }}>
       <Container>
          {
            x?.icon_img
            && <ImageContainer
            src={x?.icon_img}
            width={130}
            />
          }
          {
            !x?.icon_img
            && <BlankSquare />
          }
          <ListingHeader> {x.display_name} </ListingHeader>
          <ListingBody> {numberWithCommas(parseInt(x?.subscribers, 10))} subscribers </ListingBody>
          <ListingBody>
            {numberWithCommas(parseInt(x?.active_user_count, 10))} active users
          </ListingBody>
        </Container>
      </Link>);
      return <div>
        <SeperationLine />
        <SectionHeader>
          {item.name}
        </SectionHeader>
        <Listings>
          {newItem}
        </Listings>
      </div>;
    });
    return result;
  };

  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Playlister for Reddit</title>
      </Helmet>
      <Header>Explore</Header>
      {subRedditsJSX(redditDataState)}
    </Page>
  );
}

export default Home;
