import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import usePlaylistController from '../../Controllers/PlaylistController';
import Colours from '../../Colours/Colours';
import PlaylistRow from './PlaylistRow';
import PlaySVG from '../../Icons/PlaySVG';
import PauseSVG from '../../Icons/PauseSVG';
import HeartFillSVG from '../../Icons/HeartFillSVG';
import HeartEmptySVG from '../../Icons/HeartEmptySVG';
import {
  redditOptionsForActivePlaylist,
  playing,
  favourited,
} from '../../RecoilAtoms/Transport';
import getQueryStringParams from '../../Helpers/GetQueryStringParams';

const HeartFillElement: any = styled(HeartFillSVG)`
  height: 50px;
  width: auto;
  color: ${Colours('white')};
`;

const HeartEmptyElement: any = styled(HeartEmptySVG)`
  height: 50px;
  width: auto;
  color: ${Colours('white')};
`;

const PauseElement: any = styled(PauseSVG)`
  height: 100px;
  width: auto;
  color: ${Colours('white')};
`;

const PlayElement: any = styled(PlaySVG)`
  height: 100px;
  width: auto;
  color: ${Colours('offWhite')};
`;

const TransportButton = styled.button`
  background-color: ${Colours('dark2')};
  border: 1px solid ${Colours('dark2')};
  padding: 2px 0px 0px 0px;
  margin: 10px 0px 20px 0px;
  box-shadow: 0px;
  border-radius: 80px;
  transition: 0.2s;
  :hover {
    background-color: ${Colours('purple')};
    cursor: pointer;
  }
  :active {
    background-color: ${Colours('green')};
  }

  vertical-align:middle;
`;

const HeartButton = styled.button`
  background-color: ${Colours('dark2')};
  border: 1px solid ${Colours('dark2')};
  padding: 10px 10px 10px 10px;
  margin: 30px 0px 0px 20px;
  height: 70px;
  box-shadow: 0px;
  border-radius: 60px;
  transition: 0.2s;
  :hover {
    background-color: ${Colours('purple')};
    cursor: pointer;
  }
  :active {
    background-color: ${Colours('green')};
  }

  vertical-align:middle;
`;

const Page = styled.div`
  padding: 50px 20px 20px 20px;
`;

const Warning = styled.div`
  color: ${Colours('white')};
  font-size: 24px;
  padding: 10px 0px 10px 10px;
`;

const LoadMore = styled.button`
  color: ${Colours('white')};
  background-color: ${Colours('dark1')};
  border: 3px solid ${Colours('offWhite')};  font-size: 19px;
  padding: 10px 30px 10px 30px;
  margin: 30px 0px 30px 0px;
  border-radius: 30px;
  box-shadow: 0px; 
  :hover {
    cursor: pointer;
    background: ${Colours('dark2')};
  }
`;

const RadioButton: any = styled.button`
  color: ${Colours('white')};
  background-color: ${Colours('dark1')};
  transition: 0.3s;
  :hover {
    cursor: pointer;
    background: ${Colours('purple')};
  }
  ${(props: any) => {
    if (props.highlight) {
      return `
        background-color: ${Colours('green')};;
        :hover {
          cursor: pointer;
          background: ${Colours('green')};
        }
      `;
    }
    return '';
  }}
  border: 1px solid ${Colours('offWhite')}; 
  font-size: 19px;
  padding: 10px 30px 10px 30px;
  margin: 30px 0px 30px 0px;
  border-radius: 30px;
  box-shadow: 0px; 

  margin: 0px 10px 20px 0px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Header = styled.a`
  color: ${Colours('white')};
  font-size: 48px;
  padding: 0px 0px 0px 10px;
  overflow: hidden;
  word-wrap: break-word;
  max-width: 100%;
  text-decoration: none;
  :hover {
    color: ${Colours('white')};
    text-decoration: underline;
  }
`;

const Description = styled.div`
  color: ${Colours('white')};
  font-size: 19px;
  margin: 0px 0px 0px 10px;
  overflow: hidden;
  word-wrap: break-word;
  white-space:nowrap
  text-overflow: ellipsis;
  max-height: 125px;
  width: 100%;
`;

const SeperationLine = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${Colours('offWhite')};
  margin: 0px 0px 20px 0px;
  border-radius: 5px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: left;
  padding: 0px 0px 20px 0px;
`;

const ImageContainer = styled.img`
  width: 200px;
`;

const HeaderCol = styled.div`
  padding: 0px 10px 0px 0px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 5px;
  color:  ${Colours('white')};
  padding-bottom: 10px;
  border-bottom: 2px solid ${Colours('green')};
`;

const ColOne = styled.div`
  padding: 0px 10px 0px 10px;
  width: 3%;
`;

const ColTwo = styled.div`
  width: 72%;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px 10px 0px 0px;
`;

const ColThree = styled.div`
  width: 10%;
  // max-width: 125px;
  min-width: 75px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColFour = styled.div`
  max-width: 120px;
  min-width: 120px;
  width: 15%;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BlankSquare = styled.div`
  background-color: ${Colours('purple')};
  width: 200px;
  height: 200px;
  margin: 0px 0px 10px 0px;
`;

function PlaylistSub(props: any) {
  const [
    warning,
    localPlaylistState,
    typeFilter,
    dateFilter,
    highlightState,
    setHightlightState,
    moreMusicButton,
    doubleClick,
    onChangeValue,
  ] = usePlaylistController(
    props.match.params.playlistName,
    props.history,
    props.redditApi,
    props.location,
  );

  const [imgUrlState, setImgUrlState] = useState('');
  const [playlistDescriptionState, setPlaylistDescriptionState] = useState('');

  const [playingState, setPlaying] = useRecoilState(playing);
  const setFavourited: any = useSetRecoilState(favourited);
  useRecoilValue(favourited);
  const redditOptionsForActivePlaylistState = useRecoilValue(redditOptionsForActivePlaylist);
  const localStorageItem: any = localStorage.getItem('favourited');
  const favouritePlaylists: string[] = (localStorage.getItem('favourited')) ? JSON.parse(localStorageItem) : [];
  const localFavourited: boolean = (favouritePlaylists?.includes(props.match.params.playlistName));

  const params = getQueryStringParams(props.location.search);
  let localPlaying: any;
  if (
    props.location.pathname.includes(redditOptionsForActivePlaylistState.subReddit)
    && (
      params.type === redditOptionsForActivePlaylistState.type
      || params.type === undefined
    )
    && (
      params.dateFilter === redditOptionsForActivePlaylistState.dateFilter
      || !params.dateFilter
    )
  ) {
    localPlaying = true;
  }

  const produceList = (list: any[]) => list.map((row, index) => (
    <PlaylistRow
      {...row}
      index={index}
      highlightState={highlightState}
      buttonPress={(n: any) => setHightlightState(n)}
      doubleClick={doubleClick}
      subReddit={props.match.params.playlistName}
      typeFilter={typeFilter}
      dateFilter={dateFilter}
      redditApi={props.redditApi}
      location={props.location}
    />
  ));

  useEffect(() => {
    const data = async () => {
      const res = await props.redditApi.infoSubreddit(props.match.params.playlistName);
      setPlaylistDescriptionState(res?.data?.public_description);
      setImgUrlState(res?.data?.icon_img);
    };
    data();
  }, [
    props.location,
  ]);

  const playTrack = () => {
    if (!localPlaying) {
      doubleClick(0);
    }

    if (localPlaying) {
      setPlaying(!playingState);
    }
  };

  const favouritePlaylist = () => {
    let clone = [...favouritePlaylists];
    if (clone.includes(props.match.params.playlistName)) {
      clone = clone.filter((item: any) => (
        item !== props.match.params.playlistName
      ));
    } else {
      clone.push(props.match.params.playlistName);
    }
    localStorage.setItem('favourited', JSON.stringify(clone));
    setFavourited(clone);
  };

  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.match.params.playlistName} | Playlister for Reddit</title>
      </Helmet>
      <HeaderRow>
        <HeaderCol>
          <a href={`https://www.reddit.com/r/${props.match.params.playlistName}`}>
              {
                !imgUrlState
                && <BlankSquare />
              }
              {
                imgUrlState
                && <ImageContainer src={imgUrlState} />
              }
            </a>
        </HeaderCol>
        <HeaderCol>
          <Header
            href={`https://www.reddit.com/r/${props.match.params.playlistName}`}
          > {props.match.params.playlistName} </Header>
          <Description> {playlistDescriptionState} </Description>
        </HeaderCol>
      </HeaderRow>
      <ButtonRow>
        <RadioButton
          highlight={props.location.search.includes('hot')}
          onClick={() => onChangeValue('hot') }
        > Hot </RadioButton>
        <RadioButton
          highlight={props.location.search.includes('new')}
          onClick={() => onChangeValue('new') }
        > New </RadioButton>
        <RadioButton
          highlight={(props.location.search.includes('top') && props.location.search.includes('day'))}
          onClick={() => onChangeValue('topday') }
        > Top day </RadioButton>
        <RadioButton
          highlight={(props.location.search.includes('top') && props.location.search.includes('week'))}
          onClick={() => onChangeValue('topweek') }
        > Top week </RadioButton>
        <RadioButton
          highlight={(props.location.search.includes('top') && props.location.search.includes('month'))}
          onClick={() => onChangeValue('topmonth') }
        > Top month </RadioButton>
        <RadioButton
          highlight={(props.location.search.includes('top') && props.location.search.includes('year'))}
          onClick={() => onChangeValue('topyear') }
        > Top year </RadioButton>
        <RadioButton
          highlight={(props.location.search.includes('top') && props.location.search.includes('all'))}
          onClick={() => onChangeValue('topall') }
        > Top all time </RadioButton>
      </ButtonRow>
      <SeperationLine />
      <ButtonRow>
        <TransportButton onClick={() => (playTrack())}>
          {
            localPlaying
            && playingState
            && <PauseElement />
          }
          {
            (
              !localPlaying
              || !playingState
            )
            && <PlayElement />
          }
        </TransportButton>
        <HeartButton onClick={() => (favouritePlaylist())}>
          {
            localFavourited
            && <HeartFillElement />
          }
          {
            !localFavourited
            && <HeartEmptyElement />
          }
        </HeartButton>
      </ButtonRow>
      <TableHeader>
        <ColOne>#</ColOne>
        <ColTwo>Title</ColTwo>
        <ColThree>Posted</ColThree>
        {!isMobile && <ColFour>Score</ColFour>}
      </TableHeader>
      {produceList(localPlaylistState)}
      {warning && <Warning>  {warning} </Warning> }
      <LoadMore onClick={() => moreMusicButton()}>
        Load more tracks
      </LoadMore>
    </Page>
  );
}

export default PlaylistSub;
