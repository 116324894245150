import styled from 'styled-components';
import Colours from '../../Colours/Colours';

const MobileHomeDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute; top: 0; left: 0;
  background-color: ${Colours('dark1')};
`;

const Title = styled.h1`
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: ${Colours('white')};
  width: 100%;
`;

const Row = styled.div`
  margin:0 auto;
  text-align: center;
  font-size: 19px;
  color: ${Colours('white')};
  margin: 0 auto;
  padding: 10px 0 10px 0;
  width: 100%;
`;

const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
`;

function MobileHome() {
  return (
    <MobileHomeDiv>
      <TextContainer>
        <Title>Playlister for Reddit</Title>
        <Row> Mobile app coming soon </Row>
      </TextContainer>
    </MobileHomeDiv>
  );
}

export default MobileHome;
