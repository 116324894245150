import React from 'react';
import styled from 'styled-components';
import Colours from '../../Colours/Colours';

const MobileHomeDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute; top: 0; left: 0;
  background-color: ${Colours('dark1')};
`;

const Title = styled.h1`
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: ${Colours('white')};
  width: 100%;
`;

const Row = styled.div`
  margin:0 auto;
  text-align: center;
  font-size: 19px;
  color: ${Colours('white')};
  margin: 0 auto;
  padding: 10px 0 10px 0;
  width: 100%;
`;

const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
`;

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  resetErrorState() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      this.resetErrorState();
      return (
        <MobileHomeDiv>
          <TextContainer>
            <Title>Something went wrong</Title>
            <Row> Please refresh the page to reload the app </Row>
          </TextContainer>
        </MobileHomeDiv>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
