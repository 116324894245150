import PlaylistSub from './PlaylistSub';
import PlaylistUser from './PlaylistUser';

function Playlist(props: any) {
  if (props.match.params.playlistName !== 'upvoted') {
    return <PlaylistSub {...props}/>;
  }
  return <PlaylistUser {...props} />;
}

export default Playlist;
