import React from 'react';

type Props = {
  authFailed: string;
};

function AuthFailed(props: Props) {
  return <h1> Auth failed - {props.authFailed}</h1>;
}

export default AuthFailed;
