import styled from 'styled-components';
import { useRecoilValue, useRecoilState } from 'recoil';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import PlaylistRow from './PlaylistRow';
import usePlaylistControllerUser from '../../Controllers/PlaylistControllerUser';
import Colours from '../../Colours/Colours';
import PlaySVG from '../../Icons/PlaySVG';
import PauseSVG from '../../Icons/PauseSVG';
import {
  redditOptionsForActivePlaylist,
  playing,
} from '../../RecoilAtoms/Transport';
import getQueryStringParams from '../../Helpers/GetQueryStringParams';

const PauseElement: any = styled(PauseSVG)`
  height: 100px;
  width: auto;
  color: ${Colours('white')};
`;

const PlayElement: any = styled(PlaySVG)`
  height: 100px;
  width: auto;
  color: ${Colours('offWhite')};
`;

const TransportButton = styled.button`
  background-color: ${Colours('dark2')};
  border: 1px solid ${Colours('dark2')};
  padding: 2px 0px 0px 0px;
  margin: 10px 0px 20px 0px;
  box-shadow: 0px;
  border-radius: 80px;
  transition: 0.2s;
  :hover {
    background-color: ${Colours('purple')};
    cursor: pointer;
  }
  :active {
    background-color: ${Colours('green')};
  }

  vertical-align:middle;
`;

const Row = styled.div`
  display: block;
`;

const Page = styled.div`
  padding: 50px 20px 20px 20px;
`;

const Header = styled.div`
  color: ${Colours('white')};
  font-size: 48px;
  padding: 10px 0px 10px 0px;
`;

const Warning = styled.div`
  color: ${Colours('white')};
  font-size: 24px;
  padding: 10px 0px 10px 10px;
`;

const SeperationLine = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${Colours('offWhite')};
  margin: 0px 0px 20px 0px;
  border-radius: 5px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 5px;
  color:  ${Colours('white')};
  padding-bottom: 10px;
  border-bottom: 2px solid ${Colours('green')};
`;

const ColOne = styled.div`
  padding: 0px 10px 0px 10px;
  width: 3%;
`;

const ColTwo = styled.div`
  width: 72%;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px 10px 0px 0px;
`;

const ColThree = styled.div`
  width: 10%;
  // max-width: 125px;
  min-width: 75px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColFour = styled.div`
  max-width: 120px;
  min-width: 120px;
  width: 15%;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LoadMore = styled.button`
  color: ${Colours('white')};
  background-color: ${Colours('dark1')};
  border: 2px solid ${Colours('offWhite')};  font-size: 19px;
  padding: 10px 30px 10px 30px;
  margin-bottom: 30px;
  border-radius: 30px;
  box-shadow: 0px;
  transition: 0.3s;
  :hover {
    cursor: pointer;
    background: ${Colours('green')};
  }
  margin-top: 20px;
`;

function PlaylistUser(props: any) {
  const { redditApi } = props;
  const { playlistName } = props.match.params;
  const [
    warning,
    localPlaylistState,
    highlightState,
    setHightlightState,
    moreMusicButton,
    doubleClick,
  ] = usePlaylistControllerUser(playlistName, redditApi);

  const [playingState, setPlaying] = useRecoilState(playing);
  const redditOptionsForActivePlaylistState = useRecoilValue(redditOptionsForActivePlaylist);

  const params = getQueryStringParams(props.location.search);
  let localPlaying: any;
  if (
    props.location.pathname.includes(redditOptionsForActivePlaylistState.subReddit)
    && (
      params.type === redditOptionsForActivePlaylistState.type
      || params.type === undefined
    )
    && (
      params.dateFilter === redditOptionsForActivePlaylistState.dateFilter
      || !params.dateFilter
    )
  ) {
    localPlaying = true;
  }

  const produceList = (list: any[]) => list.map((row, index) => (
    <PlaylistRow
      {...row}
      index={index}
      highlightState={highlightState}
      buttonPress={(n: any) => setHightlightState(n)}
      doubleClick={doubleClick}
      subReddit={playlistName}
      typeFilter={null}
      dateFilter={null}
      redditApi={redditApi}
      location={props.location}
    />
  ));

  const playTrack = () => {
    if (!localPlaying) {
      doubleClick(0);
    }

    if (localPlaying) {
      setPlaying(!playingState);
    }
  };

  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Upvoted | Playlister for Reddit</title>
      </Helmet>
      <Row>
        <Header> Upvoted </Header>
      </Row>
      <SeperationLine />
      <TransportButton onClick={() => (playTrack())}>
        {
          localPlaying
          && playingState
          && <PauseElement />
        }
        {
          (
            !localPlaying
            || !playingState
          )
          && <PlayElement />
        }
      </TransportButton>
      <TableHeader>
        <ColOne>#</ColOne>
        <ColTwo>Title</ColTwo>
        <ColThree>Posted</ColThree>
        {!isMobile && <ColFour>Score</ColFour>}
      </TableHeader>
      {produceList(localPlaylistState)}
      {warning && <Warning>  {warning} </Warning> }
      <LoadMore onClick={() => moreMusicButton()}>
        Load more music
      </LoadMore>
    </Page>
  );
}

export default PlaylistUser;
