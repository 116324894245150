import { useEffect, useState } from 'react';
import Subreddits from './Subreddit';

export default function useHomeController(redditApi: any) {
  const [redditData, setRedditData] = useState([]);

  const ERRORED = '__ERRORED__';
  type ErroredType = typeof ERRORED;

  function isNotErrored<T>(x: T | ErroredType): x is T {
    return x !== ERRORED;
  }

  async function promiseIgnoreErrors<T>(promises: Array<Promise<T>>): Promise<Array<T>> {
    const results = await Promise.all(promises.map((p) => p.catch(() => ERRORED as ErroredType)));
    return results.filter(isNotErrored);
  }

  const getSubRedditData = async (subreddit: any) => {
    const promises = subreddit.map((x: any) => redditApi.infoSubreddit(x.name));
    const result = await promiseIgnoreErrors(promises);
    return result.filter(Boolean);
  };

  const getRedditData = async (popularSubreddits: any) => {
    const keys = Object.keys(popularSubreddits);
    await redditApi.authenticate();
    const promises = keys.map(async (item) => getSubRedditData(popularSubreddits[item]));
    const result = await Promise.all(promises);
    const final: any = [];
    keys.map((num1, index) => {
      const finalSubRedditData = result[index].map((x: any) => (
        {
          id: x?.data?.id,
          public_description: x?.data?.public_description,
          url: x?.data?.url,
          description: x?.data?.description,
          display_name: x?.data?.display_name,
          display_name_prefixed: x?.data?.display_name_prefixed,
          icon_img: x?.data?.icon_img,
          subscribers: x?.data?.subscribers,
          active_user_count: x?.data?.active_user_count,
        }
      ));
      final.push(
        {
          name: num1,
          data: finalSubRedditData,
        },
      );
      return null;
    });
    setRedditData(final);
  };

  useEffect(() => {
    const final: any = {};
    Object.keys(Subreddits).forEach((item) => {
      const shuffled = Subreddits[item].sort(() => 0.5 - Math.random());
      final[item] = shuffled.slice(0, 10);
    });
    getRedditData(final);
  }, []);

  return redditData;
}
