import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)` 
  width: 24px; 
  height: 24px;
`;

const HomeSVG = (obj: any) => (
  <Svg viewBox="0 0 24 24" className={obj.className}>
    <path
      fill="currentColor"
      d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"
    />
  </Svg>
);

export default HomeSVG;
