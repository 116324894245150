const getQueryStringParams = (query: any) => (query
  ? (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params: any, param: any) => {
      const temp = params;
      const [key, value] = param.split('=');
      temp[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return temp;
    }, {})
  : {});

export default getQueryStringParams;
