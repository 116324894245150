import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useTransportController from '../../Controllers/TransportController';
import Colours from '../../Colours/Colours';
import ArrowSVG from '../../Icons/ArrowSVG';
import PauseSVG from '../../Icons/PauseSVG';
import PlaySVG from '../../Icons/PlaySVG';
import SkipPreviousSVG from '../../Icons/SkipPreviousSVG';
import SkipNextSVG from '../../Icons/SkipNextSVG';
import VolumeSVG from '../../Icons/VolumeSVG';
import SliderInput from './Slider';
import SoundcloudSVG from '../../Icons/SoundcloudSVG';
import RedditSVG from '../../Icons/RedditSVG';
import createLinkToPlaylist from '../../Helpers/CreateLinkToPlaylist';

import { ReactComponent as YoutubeSVG } from '../../Icons/YouTube-Logo.wine.svg';

const SoundcloudElement: any = styled(SoundcloudSVG)`
  height: 20px;
  width: auto;
  color: ${Colours('white')};
  padding: 0px 10px 5px 0px;
  margin: 10px 0px 0px 0px;
`;

const RedditElement: any = styled(RedditSVG)`
  height: 35px;
  width: auto;
  color: ${Colours('white')};
  padding: 0px 20px 0px 0px;
  align-self: flex-end;
`;

const VolumeElement: any = styled(VolumeSVG)`
  height: 20px;
  width: auto;
  color: ${Colours('white')};
`;

const PauseElement: any = styled(PauseSVG)`
  height: 45px;
  width: auto;
  color: ${Colours('white')};
`;

const PlayElement: any = styled(PlaySVG)`
  height: 45px;
  width: auto;
  color: ${Colours('white')};
`;

const SkipPreviousElement: any = styled(SkipPreviousSVG)`
  height: 20px;
  width: auto;
  color: ${Colours('white')};
`;

const SkipNextElement: any = styled(SkipNextSVG)`
  height: 20px;
  width: auto;
  color: ${Colours('white')};
`;

const TransportButton = styled.button`
  background-color: ${Colours('dark1')};
  border: 1px solid ${Colours('dark1')};
  
  padding: 3px 3px 1px 3px;
  box-shadow: 0px;
  border-radius: 30px;
  transition: 0.2s;
  :hover {
    background-color: ${Colours('purple')};
    cursor: pointer;
  }
  :active {
    background-color: ${Colours('green')};
  }

  vertical-align:middle;
`;

const ArrowElement: any = styled(ArrowSVG)`
  height: 25px;
  width: auto;
  color: ${Colours('white')};
  transition: 0.2s;
  ${(props: any) => {
    if (props.down) {
      return `
        transform: rotate(90deg);
      `;
    }
    return '';
  }}
  ${(props: any) => {
    if (props.active) {
      return `
      color: ${Colours('redditOrange')};
      `;
    }
    return '';
  }}
`;

const VoteButton = styled.button`
  background-color: ${Colours('grey')};
  border: 1px solid ${Colours('dark2')};
  box-shadow: 0px;
  border-radius: 10px;
  transition: 0.2s;
  :hover {
    background-color: ${Colours('purple')};
    cursor: pointer;
  }
  :active {
    background-color: ${Colours('green')};
  }
  margin: 3px 0px 3px 0px;
`;

const FooterDiv = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  height: 100px;
  position: fixed;
  left: 0;
  bottom: 0;
`;

const OuterLeft = styled.div`
  width: 33%;
  background-color: ${Colours('dark1')};
  color: ${Colours('dark1')};
  z-index: 100;
  display: flex;
  height: 100%;
  align-items: center;
`;

const ImageContainer = styled.img`
  padding: 0px 0px 0px 10px;
`;

const Cell = styled.div`
  padding: 0px 10px 0px 10px;
  display:inline-block;
  vertical-align:middle;
`;

const OuterLeftRow = styled.div`
  display: block;
  max-width: 200px;
  padding: 0px 10px 0px 0px;
`;

const Text = styled.div`
  color: ${Colours('white')};
  word-wrap: break-word;
  font-size: 16px;
  max-height: 60px;
  overflow: hidden;

  a:link {
    color: ${Colours('white')};
    text-decoration: none;
  }
  a:visited {
    color: ${Colours('white')};
    text-decoration: none;
  }
  a:hover {
    color: ${Colours('white')};
    text-decoration: underline;
  }
  line-height: 1;
`;

const TextPlaylist = styled.a`
  font-size: 14px;
  word-wrap: break-word;
  max-height: 10px;
  overflow: hidden;
  a:link {
    color: ${Colours('offWhite')};
    text-decoration: none;
  }
  a:visited {
    color: ${Colours('offWhite')};
    text-decoration: none;
  }
  a:hover {
    color: ${Colours('white')};
    text-decoration: underline;
  }
`;

const Inner = styled.div`
  width: 34%;
  background-color: ${Colours('dark1')};
  vertical-align: middle;
  display: flex;
`;

const InnerRow = styled.div`
  display: flex;
  padding: 0px 10px 0px 0px;
  justify-content: center;
`;

const TransportGroup = styled.div`
  padding: 10px 10px 0px 10px;
`;

const TimerDisplay = styled.div`
  padding: 0px 10px 0px 10px;
  color: ${Colours('offWhite')};
  top: 50%;
`;

const SliderContainer = styled.div`
  padding: 0px 10px 0px 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OuterRight = styled.div`
  width: 33%;
  height: 100%;
  background-color: ${Colours('dark1')};
  display: flex;
`;

const OuterRightCell = styled.div`
  padding: 0px 10px 0px 10px;
  display: flex;
  float: right;
`;

const OuterRightRow = styled.div`
  width: 100%;
  float: right;
`;

const VolumeContainer = styled.div`
  padding: 0px 10px 0px 10px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const VolumeCell = styled.div`
  padding: 0px 10px 0px 10px;
  display: flex;
  float: right;
  max-width: 210px;
  width: 90%;
`;

const AllignSouth = styled.div`
  align-self: flex-end;
  width: 100%;
  padding: 0px 0px 15px 0px;
`;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function Transport(props: any) {
  const [
    redditOptionsForActivePlaylistState,
    playingState,
    trackPositionState,
    songLengthState,
    volumeState,
    currentTrackState,
    voteState,
    previousTrack,
    playTrack,
    nextTrack,
    processTrackPosition,
    processVolume,
    vote,
  ] : any = useTransportController(props.redditApi);
  const { width } = useWindowDimensions();
  const youtubeTrack: any = (
    currentTrackState
    && currentTrackState.url
    && currentTrackState.url.includes('youtu')
  );
  const soundcloudTrack = (
    currentTrackState
    && currentTrackState.url
    && currentTrackState.url.includes('https://soundcloud')
  );
  const truncate = (str: string, n: number = 10) => (str.length > n ? `${str.substring(0, n - 3)}...` : str);

  const minutesStart = Math.floor(trackPositionState / 60);
  let secondsStart: string | number = Math.floor(trackPositionState - minutesStart * 60);
  if (secondsStart < 10) {
    secondsStart = `0${secondsStart}`;
  }

  const minutesEnd = Math.floor(songLengthState / 60);
  let secondsEnd: string | number = Math.floor(songLengthState - minutesEnd * 60);
  if (secondsEnd < 10) {
    secondsEnd = `0${secondsEnd}`;
  }
  return (
    <FooterDiv>
      <OuterLeft>
        <Cell>
          {
            currentTrackState?.permalink
            && width > 900
            && <a href={`https://www.reddit.com${currentTrackState?.permalink}`}>
              <ImageContainer
                src={currentTrackState.thumbnail}
                width="75"
              />
            </a>
          }
        </Cell>
        <Cell>
          <OuterLeftRow>
            {
              currentTrackState?.permalink
              && <Text>
                <a href={`https://www.reddit.com${currentTrackState?.permalink}`}>
                  {truncate(currentTrackState.title, 40)}
                </ a>
              </Text>
            }
          </OuterLeftRow>
          <OuterLeftRow>
            <TextPlaylist>
              <Link to={{ pathname: createLinkToPlaylist(redditOptionsForActivePlaylistState) }}>
                {truncate(
                  `${redditOptionsForActivePlaylistState.subReddit}
                  ${(redditOptionsForActivePlaylistState.type) ? ` | ${redditOptionsForActivePlaylistState.type}` : ''}
                  ${(redditOptionsForActivePlaylistState.dateFilter) ? `| ${redditOptionsForActivePlaylistState.dateFilter}` : ''}`,
                  40,
                )}
              </Link>
            </TextPlaylist>
          </OuterLeftRow>
        </Cell>
        {
          currentTrackState
          && props.redditApi.accountName
          && <Cell>
            <OuterLeftRow>
              <VoteButton onClick={() => vote(1)}>
                <ArrowElement active={voteState === true || voteState === 1} />
              </VoteButton>
            </OuterLeftRow>
            <OuterLeftRow>
              <VoteButton onClick={() => vote(-1)}>
                <ArrowElement active={voteState === -1} down={true} />
              </VoteButton>
            </OuterLeftRow>
          </Cell>
        }
      </OuterLeft>
      <Inner>
        <AllignSouth>
          <InnerRow>
            <TransportGroup>
              <TransportButton onClick={() => (previousTrack())}>
                <SkipPreviousElement />
              </TransportButton>
              <TransportButton onClick={() => (playTrack())}>
                {
                  playingState
                  && <PauseElement />
                }
                {
                  !playingState
                  && <PlayElement />
                }
              </TransportButton>
              <TransportButton onClick={() => (nextTrack())}>
                <SkipNextElement />
              </TransportButton>
            </TransportGroup>
          </InnerRow>
          <InnerRow>
            <TimerDisplay>
              {minutesStart}:{secondsStart}
            </TimerDisplay>
            <SliderContainer>
              <SliderInput
                type="range"
                min="0"
                max={songLengthState.toString()}
                value={trackPositionState}
                onChange={(e: any) => processTrackPosition(e)}
                style={{
                  width: '100%',
                  '--min': 0,
                  '--max': songLengthState.toString(),
                  '--val': trackPositionState,
                }}
              />
            </SliderContainer>
            <TimerDisplay>
              {minutesEnd}:{secondsEnd}
            </TimerDisplay>
          </InnerRow>
        </AllignSouth>
      </ Inner>
      <OuterRight>
        <AllignSouth>
          <OuterRightRow>
            <OuterRightCell>
              {
                currentTrackState
                && width > 770
                && <a href={`https://www.reddit.com${currentTrackState?.permalink}`}>
                  <RedditElement />
                </a>
              }
            </OuterRightCell>
            <OuterRightCell>
              <a href={currentTrackState?.url}>
                {
                  youtubeTrack
                  && <YoutubeSVG />
                }
                {
                  soundcloudTrack
                  && <SoundcloudElement />
                }
              </a>
            </ OuterRightCell>
          </OuterRightRow>
          <OuterRightRow>
            <VolumeCell>
              <VolumeElement />
              <VolumeContainer>
                <SliderInput
                  type='range'
                  min='1'
                  max='100'
                  value={volumeState * 100}
                  onChange={(e: any) => processVolume(e)}
                  style={{
                    width: '100%',
                    '--min': 0,
                    '--max': 100,
                    '--val': volumeState * 100,
                  }}
                />
              </VolumeContainer>
            </VolumeCell>
          </OuterRightRow>
        </AllignSouth>
      </ OuterRight>
    </FooterDiv>
  );
}

export default Transport;
