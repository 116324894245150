import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)` 
  width: 24px; 
  height: 24px;
`;

const SoundcloudSVG = (obj: any) => (
  <Svg viewBox="0 0 124.5 14.1" className={obj.className}>
    <path
      fill="currentColor"
      d="M0.2,8.5L0,10.3l0.2,1.8c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1l0.3-1.8L0.4,8.5
c0-0.1-0.1-0.1-0.1-0.1S0.2,8.4,0.2,8.5 M1.4,7.4l-0.3,2.9l0.3,2.9c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1L2,10.3L1.6,7.4
c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0C1.5,7.3,1.4,7.4,1.4,7.4 M5.2,7L5,10.3l0.2,3.5c0,0.1,0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2L6,10.3
L5.7,7c0-0.1-0.1-0.2-0.2-0.2C5.3,6.8,5.2,6.9,5.2,7 M2.7,6.8l-0.3,3.5l0.3,3.3c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1-0.1,0.1-0.2
l0.3-3.3L3,6.8c0-0.1-0.1-0.2-0.2-0.2C2.8,6.7,2.7,6.7,2.7,6.8 M3.9,6.7l-0.3,3.6l0.3,3.4c0,0.1,0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2
l0.3-3.4L4.3,6.7c0-0.1-0.1-0.2-0.2-0.2S4,6.6,3.9,6.7 M6.5,4.9l-0.3,5.4l0.3,3.5c0,0.1,0.1,0.2,0.2,0.2C6.9,14,7,13.9,7,13.8
l0.3-3.5L7,4.9c0-0.1-0.1-0.2-0.2-0.2C6.6,4.7,6.5,4.8,6.5,4.9 M7.8,3.7l-0.2,6.6l0.2,3.5c0,0.2,0.1,0.3,0.3,0.3
c0.1,0,0.3-0.1,0.3-0.3l0.3-3.5L8.4,3.7c0-0.2-0.1-0.3-0.3-0.3C7.9,3.4,7.8,3.5,7.8,3.7 M69.5,3.3v3.8v2c-0.1-0.2-0.4-0.7-0.6-1
l-3.2-4.8H64v9.1h1.8v-4v-2c0.1,0.2,0.4,0.7,0.6,1l3.3,5h1.6V3.3H69.5z M75.5,5.1h1c1.9,0,2.7,0.9,2.7,2.8c0,1.7-0.9,2.8-2.8,2.8
h-0.9V5.1z M73.7,12.4h2.7c2.3,0,4.7-1.3,4.7-4.5c0-3.3-1.9-4.5-4.5-4.5h-2.9V3.3V12.4z M91.6,3.3v9.1h5.5v-1.8h-3.6V3.3H91.6z
 M119,5.1h1c1.9,0,2.7,0.9,2.7,2.8c0,1.7-0.9,2.8-2.8,2.8H119V5.1z M117.2,12.4h2.7c2,0,4.1-1,4.6-3.4V6.7
c-0.4-2.4-2.1-3.3-4.4-3.3h-2.9V3.3V12.4z M36.5,5.9c0,1.8,1.2,2.3,2.8,2.7c1.7,0.4,2,0.6,2,1.2c0,0.7-0.5,1-1.6,1
c-0.9,0-1.7-0.3-2.3-1L36.1,11c0.7,1,2,1.7,3.5,1.7c2.5,0,3.6-1.2,3.6-2.9c0-2-1.6-2.5-3-2.8c-1.4-0.3-1.8-0.5-1.8-1.1
c0-0.4,0.3-0.8,1.3-0.8c0.8,0,1.5,0.3,2.1,0.9l1.3-1.3c-0.9-0.9-1.9-1.4-3.3-1.4c-0.1,0-0.2,0-0.3,0C37.8,3.3,36.5,4.3,36.5,5.9
 M46.5,7.9c0-1.6,0.6-2.9,2.1-2.9s2.1,1.3,2.1,2.9c0,1.6-0.6,2.9-2.1,2.9S46.5,9.5,46.5,7.9 M44.6,7.9c0,2.8,1.5,4.7,3.9,4.7
c2.5,0,4-1.9,4-4.7c0-2.8-1.5-4.7-4-4.7C46.1,3.2,44.6,5.1,44.6,7.9 M59.9,3.2L59.9,3.2v5.2c0,1.5-0.7,2.4-1.9,2.4
s-1.9-0.9-1.9-2.4V3.3h-1.8v5.1c0,2.6,1.5,4.1,3.7,4.1c2.3,0,3.7-1.5,3.7-4.2V3.2H59.9z M82.6,7.9c0,2.9,1.6,4.7,4,4.7
c1.7,0,2.7-0.8,3.4-2.1l-1.5-0.9c-0.5,0.8-1,1.2-1.8,1.2c-1.4,0-2.2-1.2-2.2-2.9c0-1.7,0.8-2.9,2.1-2.9c0.8,0,1.3,0.4,1.7,1
L90,5.2c-0.6-1.2-1.6-2-3.3-2C84.3,3.2,82.6,5.1,82.6,7.9 M99.8,7.9c0-1.6,0.6-2.9,2.1-2.9c1.5,0,2.1,1.3,2.1,2.9
c0,1.6-0.6,2.9-2.1,2.9C100.4,10.8,99.8,9.5,99.8,7.9 M97.9,7.9c0,2.8,1.5,4.7,3.9,4.7c2.5,0,3.9-1.9,3.9-4.7
c0-2.8-1.5-4.7-3.9-4.7C99.4,3.2,97.9,5.1,97.9,7.9 M113.1,3.2L113.1,3.2v5.2c0,1.5-0.7,2.4-1.9,2.4c-1.2,0-1.9-0.9-1.9-2.4V3.3
h-1.8v5.1c0,2.6,1.5,4.1,3.7,4.1c2.3,0,3.7-1.5,3.7-4.2V3.2H113.1z M13.1,3.4l-0.2,6.9l0.2,3.3c0,0.2,0.2,0.4,0.4,0.4
s0.4-0.2,0.4-0.4l0.2-3.3l-0.2-7c0-0.2-0.2-0.4-0.4-0.4C13.2,3,13.1,3.1,13.1,3.4 M9.1,3.1l-0.2,7.2l0.2,3.4
c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3l0.2-3.4L9.7,3.1c0-0.2-0.1-0.3-0.3-0.3C9.2,2.8,9.1,3,9.1,3.1 M11.7,3.1l-0.2,7.2
l0.2,3.4c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4l0.2-3.4l-0.2-7.2c0-0.2-0.2-0.4-0.4-0.4C11.9,2.7,11.7,2.9,11.7,3.1
 M10.4,2.9l-0.2,7.4l0.2,3.4c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3l0.2-3.4L11,2.9c0-0.2-0.2-0.3-0.3-0.3
C10.6,2.6,10.4,2.7,10.4,2.9 M14.4,2l-0.2,8.3l0.2,3.3c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4l0.2-3.3L15.2,2
c0-0.2-0.2-0.4-0.4-0.4S14.4,1.8,14.4,2 M15.8,1.3l-0.2,9l0.2,3.3c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4l0.2-3.3l-0.2-9
c0-0.2-0.2-0.4-0.4-0.4c0,0-0.1,0-0.1,0C15.9,0.9,15.8,1.1,15.8,1.3 M17.4,0.4C17.1,0.5,17,0.6,17,0.9v12.7c0,0.2,0.2,0.4,0.4,0.5
h11.1c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4c-0.5,0-1.1,0.1-1.5,0.3C26.7,2.8,23.7,0,20,0c-0.2,0-0.3,0-0.5,0C18.8,0,18.1,0.2,17.4,0.4"
    />
  </Svg>
);

export default SoundcloudSVG;
