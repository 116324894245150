import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  currentTrack,
  activePlaylist,
  playing,
  redditOptionsForActivePlaylist,
  seekTo,
  recentlySelected,
} from '../RecoilAtoms/Transport';

function useURLParams(location: any) {
  const [typeFilter, setTypeFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const qType = (new URLSearchParams(location.search).get('type') ? new URLSearchParams(location.search).get('type') : 'hot');
  const qDateFilter = (new URLSearchParams(location.search).get('dateFilter') ? new URLSearchParams(location.search).get('dateFilter') : '');

  if (qType && qType !== typeFilter) {
    setTypeFilter(qType);
  }
  if (qDateFilter && qDateFilter !== dateFilter) {
    setDateFilter(qDateFilter);
  }
  return [typeFilter, dateFilter] as const;
}

export default function usePlaylistController(
  playlistName: string,
  history: any,
  redditApi: any,
  location: any,
) {
  // local state
  const [localPlaylistState, setLocalPlaylistState] = useState([]);
  const [highlightState, setHightlightState] = useState(-1);
  const [warning, setWarning] = useState('');
  const [localSearchIDState, setLocalSearchID] = useState('');

  // custom hooks
  const [typeFilter, dateFilter] = useURLParams(location);

  // recoil state
  const setCurrentTrack = useSetRecoilState(currentTrack);
  const setSeekTo = useSetRecoilState(seekTo);
  const [activePlaylistState, setActivePlaylist] = useRecoilState(activePlaylist);
  const [recentlySelectedState, setRecentlySelected] = useRecoilState(recentlySelected);
  const [playingState, setPlaying] = useRecoilState(playing);
  const [
    redditOptionsForActivePlaylistState,
    setRedditOptionsForActivePlaylist,
  ] = useRecoilState(redditOptionsForActivePlaylist);

  const LIMIT = 100;

  const addId = (arr: any) => arr.map((obj: any, index: any) => ({ ...obj, id: index }));

  // Fetches tracks from reddit and updates the local playlist view
  // If local playlist is active in the global space, it will also update the
  // global playlist
  const fetchRedditdata = async (rOptions: any, playlistState: any) => {
    setWarning('');
    const [
      playlist,
      lastTrack,
    ] = await redditApi.getMusicLinksFromSubreddit(rOptions);
    if (!playlist) {
      setWarning('No more music');
      return;
    }

    setLocalSearchID(lastTrack);
    const clone = [...playlistState];
    clone.push(...playlist);
    const final = addId(clone);
    setLocalPlaylistState(final);

    if (
      redditOptionsForActivePlaylistState.subReddit === playlistName
      && redditOptionsForActivePlaylistState.type === rOptions.type
      && redditOptionsForActivePlaylistState.dateFilter === rOptions.dateFilter
    ) {
      setActivePlaylist(final);
      const cloneOptions = { ...redditOptionsForActivePlaylistState };
      cloneOptions.after = localSearchIDState;
      // Updating the last track ID in the global space
      // so it can carry on updating correctly after the local playlist disappears
      setRedditOptionsForActivePlaylist(cloneOptions);
    }
  };

  // Loads existing playlist if params change and are the same as active playlist
  useEffect(() => {
    if (
      redditOptionsForActivePlaylistState.subReddit === playlistName
        && redditOptionsForActivePlaylistState.type === typeFilter
        && redditOptionsForActivePlaylistState.dateFilter === dateFilter
    ) {
      setLocalPlaylistState(activePlaylistState);
    } else {
      setLocalSearchID('');
      setLocalPlaylistState([]);
      const redditOptions = {
        subReddit: playlistName,
        limit: LIMIT,
        before: '',
        after: '',
        type: typeFilter,
        dateFilter,
      };
      fetchRedditdata(redditOptions, []);
    }
  }, [
    typeFilter,
    dateFilter,
    playlistName,
  ]);

  // If activeplaylist updates in the global space, this will trigger
  // the local view to update as well.
  useEffect(() => {
    if (
      redditOptionsForActivePlaylistState.subReddit === playlistName
      && redditOptionsForActivePlaylistState.type === typeFilter
      && redditOptionsForActivePlaylistState.dateFilter === dateFilter
    ) {
      setLocalPlaylistState(activePlaylistState);
    }
  }, [
    activePlaylistState,
  ]);

  // Handles the doubleclick
  const doubleClick = (n: number) => {
    if (
      redditOptionsForActivePlaylistState.subReddit !== playlistName
      || redditOptionsForActivePlaylistState.type !== typeFilter
      || redditOptionsForActivePlaylistState.dateFilter !== dateFilter
    ) {
      setActivePlaylist(localPlaylistState);
      const clone = { ...redditOptionsForActivePlaylistState };
      clone.subReddit = playlistName;
      clone.type = typeFilter;
      clone.dateFilter = dateFilter;
      clone.after = localSearchIDState;
      setRedditOptionsForActivePlaylist(clone);

      let recentlySelectedClone: any = [...recentlySelectedState];
      recentlySelectedClone = recentlySelectedClone.filter((e: any) => (
        e.subReddit !== clone.subReddit
        || e.type !== clone.type
        || e.dateFilter !== clone.dateFilter
      ));

      if (recentlySelectedClone.length > 4) {
        recentlySelectedClone.shift();
      }
      recentlySelectedClone.push(clone);
      setRecentlySelected(recentlySelectedClone);
      localStorage.setItem('recentlySelected', JSON.stringify(recentlySelectedClone));
    }
    setSeekTo(0);
    setCurrentTrack(n);
    if (!playingState) {
      setPlaying(true);
    }
  };

  // The switch for the playlist view
  const onChangeValue = (e: any) => {
    setWarning('');
    let newType = '';
    let newDateFilter = '';

    if (e === 'hot' || e === 'new') {
      newType = e;
    }
    if (e !== 'hot' && e !== 'new') {
      newType = 'top';
      if (e.includes('day')) {
        newDateFilter = 'day';
      } else if (e.includes('week')) {
        newDateFilter = 'week';
      } else if (e.includes('month')) {
        newDateFilter = 'month';
      } else if (e.includes('year')) {
        newDateFilter = 'year';
      } else if (e.includes('all')) {
        newDateFilter = 'all';
      }
    }

    if (newDateFilter) {
      history.push({
        search: `?${new URLSearchParams({ type: newType, dateFilter: newDateFilter }).toString()}`,
      });
    } else {
      history.push({
        search: `?${new URLSearchParams({ type: newType }).toString()}`,
      });
    }
  };

  const moreMusicButton = () => fetchRedditdata(
    {
      subReddit: playlistName,
      limit: LIMIT,
      before: '',
      after: localSearchIDState,
      type: typeFilter,
      dateFilter,
    },
    localPlaylistState,
  );

  return [
    warning,
    localPlaylistState,
    typeFilter,
    dateFilter,
    highlightState,
    setHightlightState,
    moreMusicButton,
    doubleClick,
    onChangeValue,
  ] as const;
}
