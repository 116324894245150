import Routes from '../Routes/Routes';

const createLinkToPlaylist = (redditOptions: any) : string | undefined => {
  if (!redditOptions.subReddit) {
    return undefined;
  }
  const { subReddit } = redditOptions;

  let params = '';
  if (redditOptions.type) {
    params = `?type=${redditOptions.type}`;
  }
  if (redditOptions.dateFilter) {
    params = `${params}&dateFilter=${redditOptions.dateFilter}`;
  }
  return `${Routes('playlist')}/${subReddit}/${params}`;
};

export default createLinkToPlaylist;
