import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  playing,
  songLength,
  volume,
  currentTrack,
  activePlaylist,
  seekTo,
  redditOptionsForActivePlaylist,
  reactPlayerPosition,
} from '../RecoilAtoms/Transport';

export default function useTransportController(redditApi: any) {
  const [playingState, setPlaying] = useRecoilState(playing);
  const currentTrackState: number = useRecoilValue(currentTrack);
  const setCurrentTrack = useSetRecoilState(currentTrack);
  const [volumeState, setVolume] = useRecoilState(volume);
  const setSeekTo = useSetRecoilState(seekTo);
  const [activePlaylistState, setActivePlaylist] = useRecoilState(activePlaylist);
  const [voteState, setVote] = useState(0);
  const songLengthState = useRecoilValue(songLength);
  const maxNumberOfTracks: number = activePlaylistState.length;
  const [
    redditOptionsForActivePlaylistState,
    setRedditOptionsForActivePlaylist,
  ] = useRecoilState(redditOptionsForActivePlaylist);

  const reactPlayerPositionState = useRecoilValue(reactPlayerPosition);

  function previousTrack() {
    setSeekTo(0);
    if (reactPlayerPositionState && reactPlayerPositionState < 3) {
      if (currentTrackState > 0) {
        setCurrentTrack(currentTrackState - 1);
      }
    }
  }

  function nextTrack() {
    if (currentTrackState < maxNumberOfTracks - 1) {
      setCurrentTrack(currentTrackState + 1);
    } else {
      setPlaying(false);
    }
  }

  function playTrack() {
    if (activePlaylistState.length) {
      setPlaying(!playingState);
    } else {
      setPlaying(false);
    }
  }

  function processVolume(e: any) {
    let targetVol = parseInt(e.target.value, 10) / 100;
    if (targetVol < 0.02) {
      targetVol = 0;
    }
    setVolume(targetVol);
  }

  function processTrackPosition(e: any) {
    setSeekTo(parseInt(e.target.value, 10));
  }

  const addId = (arr: any) => arr.map((obj: any, index: number) => ({ ...obj, id: index }));

  const fetchRedditdata = async (rOptions: any, playlistState: any) => {
    let playlist;
    let lastTrackID;
    if (rOptions?.subReddit === 'mymusic') {
      [playlist, lastTrackID] = await redditApi.getUpvoted(rOptions);
    } else {
      [playlist, lastTrackID] = await redditApi.getMusicLinksFromSubreddit(rOptions);
    }

    if (!playlist) {
      return;
    }

    const clone = [...playlistState];
    clone.push(...playlist);
    const final = addId(clone);
    setActivePlaylist(final);
    const cloneRedditSearchParams = { ...redditOptionsForActivePlaylistState };
    cloneRedditSearchParams.after = lastTrackID;
    setRedditOptionsForActivePlaylist(cloneRedditSearchParams);
  };

  // Gets more tracks if playlist runs out
  useEffect(() => {
    if (currentTrackState === activePlaylistState.length - 1) {
      fetchRedditdata(redditOptionsForActivePlaylistState, activePlaylistState);
    }
    const temp : any = { ...activePlaylistState };
    setVote(temp[currentTrackState]?.likes);
  }, [
    currentTrackState,
    activePlaylistState,
  ]);

  async function vote(dir: number) {
    if (
      !activePlaylistState[currentTrackState]
      || (voteState + dir > 1)
      || (voteState + dir < -1)
      || !redditApi.accountName
    ) {
      return;
    }

    setVote(voteState + dir);
    const clone: any = [...activePlaylistState];
    const track = { ...clone[currentTrackState] };
    track.likes = voteState + dir;
    clone[track.id] = track;
    setActivePlaylist(clone);
    await redditApi.votePost(
      dir,
      `t3_${clone[currentTrackState]?.redditId}`,
    );
  }

  return [
    redditOptionsForActivePlaylistState,
    playingState,
    reactPlayerPositionState,
    songLengthState,
    volumeState,
    activePlaylistState[currentTrackState],
    voteState,
    previousTrack,
    playTrack,
    nextTrack,
    processTrackPosition,
    processVolume,
    vote,
  ] as const;
}
