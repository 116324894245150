interface ColoursInterface {
  dark1: string;
  dark2: string;
  purple: string;
  green: string;
  pink: string;
  white: string;
  offWhite: string;
  grey: string;
  darkgrey: string;
  redditOrange: string;
}

export default function Colours(colour: string) : string {
  const colours : ColoursInterface = {
    dark1: '#090909',
    dark2: '#172221',
    purple: '#2F4954',
    green: '#376E6F',
    pink: '#DA7B93',
    white: '#F9F8FA',
    offWhite: '#CDCDCD',
    grey: '#343434',
    darkgrey: '#2A2A30',
    redditOrange: '#FF4500',
  };
  return (colours as any)[colour];
}
