import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)` 
  width: 24px; 
  height: 24px;
`;

const PlaySVG = (obj: any) => (
  <Svg viewBox="0 0 24 24" className={obj.className}>
    <path
      fill="currentColor"
      d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8 S16.41,20,12,20z M9.5,16.5l7-4.5l-7-4.5V16.5z"
    />
  </Svg>
);

export default PlaySVG;
