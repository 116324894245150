interface RoutesInterface {
  search: string
  home: string
  homeAlt: string
  playlist: string
  account: string
}

function Routes(route: string) : string {
  const routes: RoutesInterface = {
    search: '/search',
    home: '/',
    homeAlt: '/home/',
    playlist: '/playlist',
    account: '/account/',
  };
  return (routes as any)[route];
}

export default Routes;
