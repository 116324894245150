import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { Link, useLocation } from 'react-router-dom';
import { favourited } from '../../RecoilAtoms/Transport';
import Routes from '../../Routes/Routes';
import HomeSVG from '../../Icons/HomeSVG';
import SearchSVG from '../../Icons/SearchSVG';
import ArrowSVG from '../../Icons/ArrowSVG';
import SettingsSVG from '../../Icons/SettingsSVG';
import RedditSVG from '../../Icons/RedditSVG';
import Colours from '../../Colours/Colours';

const Scroll = styled.div`
  overflow: scroll;
`;

const ArrowElement: any = styled(ArrowSVG)`   
  height: 35px;
  width: auto;
`;

const HomeElement: any = styled(HomeSVG)`   
  height: 35px;
  width: auto;
`;

const SearchElement: any = styled(SearchSVG)`   
  height: 35px;
  width: auto;
`;

const SettingsElement: any = styled(SettingsSVG)`   
  height: 35px;
  width: auto;
`;

const RedditElement: any = styled(RedditSVG)`   
  height: 35px;
  width: auto;
`;

const NavBarDiv: any = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 100px;
  overflow: hidden;
  background-color: ${Colours('dark1')};

  a {
    color: black;
    text-decoration: none; /* no underline */
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
  }
`;

const Row : any = styled.div`
  width: calc(100% - 30px);
  font-size: 19px;
  color: ${Colours('offWhite')};
  vertical-align: middle;
  margin: 10px 0px 10px 15px;
  padding: 10px 0px 10px 0px;
  border-radius: 10px;

  ${(props: any) => {
    if (props?.selected) {
      return `
        background-color: ${Colours('grey')};
        color: ${Colours('white')};
      `;
    }
    return `
      opacity: 0.8;
      transition: 0.3s;
      :hover {opacity: 1}
      `;
  }}
`;

const NonInteractiveRow = styled.div`
  width: calc(100% - 30px);
  font-size: 19px;
  color: ${Colours('white')};
  vertical-align: middle;
  margin: 0px 0px 10px 15px;
  padding: 10px 0px 10px 0px;
  border-radius: 10px;
`;

const Cell = styled.div`
  padding: 0px 15px 0px 15px;
  display:inline-block;
  vertical-align:middle;
`;

const Title = styled.h1`
  font-family: 'Baloo Chettan 2';
  font-size: 24px;
  font-weight: bold;
  color: ${Colours('white')};
  padding: 10px 30px 10px 30px;
`;

const SeperationLine = styled.div`
  height: 2px;
  width: calc(100% - 30px);
  background-color: ${Colours('offWhite')};
  margin: 10px 0px 10px 15px;
  border-radius: 5px;
`;

function Navbar(props: any) {
  const location = useLocation();
  const selectedSearch: boolean = (location.pathname === '/search/');
  const selectedMyMusic: boolean = (location.pathname === '/playlist/upvoted/');
  const selectedHome: boolean = (location.pathname === '/home/');
  const accountSettings: boolean = (location.pathname === '/account/');
  useRecoilState(favourited);

  const localStorageItem: any = localStorage.getItem('favourited');
  const favouritePlaylists: string[] = JSON.parse(localStorageItem);

  let signinField;
  if (props.connectingToAPI) {
    signinField = <Row>
    </Row>;
  } else if (props.signedIn) {
    signinField = <Link to={`${Routes('playlist')}/upvoted/`}>
      <Row
        selected={selectedMyMusic}
      >
        <Cell>
          <ArrowElement />
        </Cell>
        <Cell>
          Upvoted
        </Cell>
      </Row>
    </Link>;
  } else {
    signinField = <a href={props.redditSignInURL}>
      <Row>
        <Cell>
          <RedditElement />
        </ Cell>
        <Cell>
          Connect
        </Cell>
      </Row>
    </a>;
  }

  const produceFavourites = (list: string[]) => list.map((row) => <Link to={`/playlist/${row}/?type=hot`}>
      <Row>
        <Cell>
          {row}
        </Cell>
      </Row>
    </Link>);

  return (
    <NavBarDiv>
      <Link to={Routes('home')}>
        <Title> Playlister </Title>
      </Link>
      <Link to={Routes('homeAlt')}>
        <Row
          selected={selectedHome}
        >
          <Cell>
            <HomeElement/>
          </Cell>
          <Cell>
            Home
          </Cell>
        </Row>
      </Link>
      <Link to={`${Routes('search')}/`}>
        <Row
          selected={selectedSearch}
        >
          <Cell>
            <SearchElement />
          </Cell>
          <Cell>
            Search
          </Cell>
        </Row>
      </Link>
      {signinField}
      <Link to={Routes('account')}>
        <Row
          selected={accountSettings}
        >
          <Cell> <SettingsElement /> </Cell>
          <Cell> Account </Cell>
        </Row>
      </ Link>
      <NonInteractiveRow>
        <SeperationLine />
      </NonInteractiveRow>
      <NonInteractiveRow>
        <Cell>
          Favorites
        </Cell>
      </NonInteractiveRow>
      <Scroll>
        {favouritePlaylists && produceFavourites(favouritePlaylists.slice(0).reverse())}
      </Scroll>
    </NavBarDiv>
  );
}

export default Navbar;
