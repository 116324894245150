import React, {
  useState, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import { useRecoilValue, useRecoilState } from 'recoil';
import { isMobile } from 'react-device-detect';
import {
  currentTrack,
  redditOptionsForActivePlaylist,
  activePlaylist,
} from '../../RecoilAtoms/Transport';
import Colours from '../../Colours/Colours';
import ArrowSVG from '../../Icons/ArrowSVG';
import getQueryStringParams from '../../Helpers/GetQueryStringParams';

const PlaylistRowButton: any = styled.div`
  display: block;
  background-color: ${(props: any) => ((props.highlight) ? Colours('green') : Colours('dark2'))};
  :hover {
    background-color: ${(props: any) => ((props.highlight) ? Colours('green') : Colours('darkgrey'))};
  }
  color: ${Colours('white')};
  background-color: ${(props: any) => ((props.playing) ? Colours('purple') : Colours('dark2'))};
  margin: 10px 0px 10px 0px;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
  user-select: none;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 5px;
  min-height: 32px;
`;

const CenterText = styled.div`
  display: table-cell; 
  vertical-align: middle;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColOne = styled.div`
  padding: 0px 10px 0px 10px;
  width: 3%;
  display: table-cell;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ColTwo = styled.div`
  width: 72%;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px 10px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ColThree = styled.div`
  display: flex;
  width: 10%;
  min-width: 75px;
  display: table;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ColFour = styled.div`
  max-width: 120px;
  min-width: 120px;
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VoteButton = styled.button`
  background-color: ${Colours('grey')};
  border: 1px solid ${Colours('dark2')};
  box-shadow: 0px;
  border-radius: 10px;
  transition: 0.2s;
  :hover {
    background-color: ${Colours('purple')};
    cursor: pointer;
  }
  :active {
    background-color: ${Colours('green')};
  }
`;

const ArrowElement: any = styled(ArrowSVG)`   
  height: 25px;
  width: auto;
  color: ${Colours('white')};
  transition: 0.2s;
  ${(props: any) => {
    if (props.down) {
      return `
        transform: rotate(90deg);
      `;
    }
    return '';
  }}
  ${(props: any) => {
    if (props.active) {
      return `
      color: ${Colours('redditOrange')};
      `;
    }
    return '';
  }}
`;

function PlaylistRow(props: any) {
  const wrapperRef: any = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [time, setTime] = useState(2);
  const [timeActive, setTimeActive] = useState(false);
  const currentTrackState: any = useRecoilValue(currentTrack);
  const redditOptionsForActivePlaylistState = useRecoilValue(redditOptionsForActivePlaylist);
  const [activePlaylistState, setActivePlaylist] = useRecoilState(activePlaylist);
  const [voteState, setVote] = useState(props.likes);
  const { redditApi } = props;

  const handleClickOutside = (event: any) => {
    if (
      wrapperRef
      && wrapperRef.current
      && !wrapperRef?.current?.contains(event.target)
    ) {
      document.removeEventListener('click', handleClickOutside, false);
      setIsVisible(false);
    }
  };

  const click = () => {
    if (isMobile) {
      props.doubleClick(props.index);
      setTimeActive(false);
      return;
    }

    props.buttonPress(props.index);
    setIsVisible(true);
    document.addEventListener('click', handleClickOutside, false);
    if (!timeActive) {
      setTimeActive(true);
    }
    if (timeActive) {
      props.doubleClick(props.index);
      setTimeActive(false);
    }
  };

  const onKeyPress = (e: any) => {
    if (e.keyCode === 0) {
      props.doubleClick(props.index);
    }
  };

  useEffect(() => {
    let interval: any = null;
    if (time === 0) {
      clearInterval(interval);
      setTimeActive(false);
      setTime(2);
    }

    if (timeActive) {
      interval = setInterval(() => {
        setTime((seconds: any) => parseInt(seconds, 10) - 1);
      }, 1000);
    } else if (!timeActive) {
      clearInterval(interval);
      setTime(2);
    }
    return () => clearInterval(interval);
  }, [
    time,
    timeActive,
  ]);

  const params = getQueryStringParams(props.location.search);
  let localPlaying;
  if (
    props.location.pathname.includes(redditOptionsForActivePlaylistState.subReddit)
    && (
      params.type === redditOptionsForActivePlaylistState.type
      || params.type === undefined
    )
    && (
      params.dateFilter === redditOptionsForActivePlaylistState.dateFilter
      || !params.dateFilter
    )
    && currentTrackState === props.id
  ) {
    localPlaying = true;
  }

  const msToTime = (duration: number) => {
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const days = Math.floor((duration / (1000 * 60 * 60)) / 24);

    if (days > 0) {
      if (days === 1) {
        return `${days} day ago`;
      }
      return `${days} days ago`;
    }

    if (hours > 0) {
      if (hours === 1) {
        return `${hours} hour ago`;
      }
      return `${hours} hours ago`;
    }

    if (minutes > 0) {
      if (minutes === 1) {
        return `${hours} minute ago`;
      }
      return `${minutes} minutes ago`;
    }

    return 'now';
  };

  const timeSincePosted = (timeUTC: number) => msToTime(Date.now() - (timeUTC * 1000));

  const formatScore = (score: number) => {
    if (score < 1000) {
      return score;
    }
    return `${(score / 1000).toFixed(1)}k`;
  };

  async function vote(dir: number) {
    if (
      (voteState + dir > 1)
      || (voteState + dir < -1)
      || !redditApi.accountName
    ) {
      return;
    }
    setVote(voteState + dir);
    const clone: any = [...activePlaylistState];
    const track: any = { ...clone[currentTrackState] };
    track.likes = voteState + dir;
    clone[track.id] = track;
    setActivePlaylist(clone);
    await redditApi.votePost(
      dir,
      `t3_${props?.redditId}`,
    );
  }

  return (
    <PlaylistRowButton
      ref={wrapperRef}
      value={props.value}
      onClick={() => click()}
      onKeyPress={(e: any) => onKeyPress(e)}
      highlight={isVisible}
      playing={localPlaying}
    >
      <Row>
        <ColOne>{props.index + 1}</ColOne>
        <ColTwo>{props.title.replace('&amp;', '')}</ColTwo>
        <ColThree>
          <CenterText>
            {timeSincePosted(props.created_utc)}
          </CenterText>
        </ColThree>
        {!isMobile
        && <ColFour>
        {redditApi.accountName
        && <VoteButton onClick={() => vote(1)}>
            <ArrowElement active={voteState === 1 || voteState === true} />
          </VoteButton>
        }
          <CenterText>
            {formatScore(props.score)}
          </CenterText>
          {
            redditApi.accountName
            && <VoteButton onClick={() => vote(-1)}>
            <ArrowElement active={voteState === -1} down={true} />
          </VoteButton>
          }
        </ColFour>
        }
      </Row>
    </PlaylistRowButton>
  );
}

export default PlaylistRow;
