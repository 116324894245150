import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Svg = styled(Icon)` 
  width: 24px; 
  height: 24px;
  transform: rotate(270deg);
`;

const ArrowSVG = (obj: any) => (
  <Svg viewBox="0 0 24 24" className={obj.className}>
    <path
      fill="currentColor"
      d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z"
    />
  </Svg>
);

export default ArrowSVG;
