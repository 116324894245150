import { atom } from 'recoil';

export const playing = atom({
  key: 'playing',
  default: false,
});

export const seekTo = atom({
  key: 'seekTo',
  default: 0,
});

export const songLength = atom({
  key: 'songLength',
  default: 0,
});

export const volume = atom({
  key: 'volume',
  default: 100,
});

export const currentTrack: any = atom({
  key: 'currentTrack',
  default: 0,
});

export const redditOptionsForActivePlaylist = atom({
  key: 'redditOptionsForActivePlaylist',
  default: {
    subReddit: '',
    type: '',
    dateFilter: '',
    after: '',
  },
});

export const activePlaylist = atom({
  key: 'activePlaylist',
  default: [],
});

export const recentlySelected = atom({
  key: 'recentlySelected',
  default: [],
});

export const reactPlayerPosition = atom({
  key: 'reactPlayerPosition',
  default: 0,
});

export const favourited = atom({
  key: 'favourited',
  default: [],
});
