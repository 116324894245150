import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import GA4React from 'ga-4-react';
import { createBrowserHistory } from 'history';
import { isMobileOnly } from 'react-device-detect';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import Routes from './Routes/Routes';
import RedditAPI from './Helpers/RedditAPI';
import AuthFailed from './DesktopView/AuthFailed/AuthFailed';
import Home from './DesktopView/Home/Home';
import Account from './DesktopView/Account/Account';
import Navbar from './DesktopView/Navbar/Navbar';
import Transport from './DesktopView/Transport/Transport';
import Playlist from './DesktopView/Playlist/Playlist';
import SearchDesktopView from './DesktopView/Search/Search';
import ErrorBoundary from './DesktopView/Error/ErrorBoundary';
import Player from './Player/Player';
import MobileHome from './MobileView/MobileHome/MobileHome';
import Colours from './Colours/Colours';
import {
  signedIn,
  accountName,
} from './RecoilAtoms/Auth';

const Main = styled.div`
  height: calc(100% - 100px);
  width: calc(100% - 250px);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 250px;
  bottom: 100px;
  background-image: linear-gradient(${Colours('dark2')}, ${Colours('dark1')});
  overflow-x: hidden;
`;

const history = createBrowserHistory();

const redditApi: RedditAPI = new RedditAPI();

function stoperror() {
  return true;
}

if (window.location.href.includes('playlisterforreddit.com')) {
  try {
    setTimeout(() => {
      const ga4react: GA4React = new GA4React('G-ZRRR4B603H');
      ga4react.initialize();
      history.listen((location: any) => {
        ga4react.pageview(location.pathname); // Record a pageview for the given page
      });
    }, 4000);
  } catch (err) {
    stoperror();
  }
}
function App() {
  const [connectingToAPI, setConnectingToAPI] = useState(true);
  const [authFailed, setAuthFailed] = useState('');
  const redditSignInURL = redditApi.getTokenURL();

  const [signedInState, setSignedIn] = useRecoilState(signedIn);
  const setAccountName = useSetRecoilState(accountName);

  useEffect(() => {
    const fetchRedditAuth = async () => {
      const res = await redditApi.authenticate();
      if (res && res.error) {
        setAuthFailed(res.error);
      } else if (res) {
        setSignedIn(redditApi.checkIfSignedIn());
        await redditApi.getCurrentAccount();
        setAccountName(redditApi.accountName);
      } else {
        setAuthFailed('Unknown failure');
      }
      setConnectingToAPI(false);
    };

    fetchRedditAuth();
  });

  async function signOut() {
    await redditApi.signOut();
    setSignedIn(false);
  }

  if (authFailed) {
    return (
      <AuthFailed
        authFailed={authFailed}
      />
    );
  }

  if (isMobileOnly) {
    return <MobileHome />;
  }

  return (
      <Router>
        <div>
          <Navbar
            signedIn={signedInState}
            connectingToAPI={connectingToAPI}
            redditSignInURL={redditSignInURL}
            signOut={signOut}
          />
          <Main>
            <ErrorBoundary>
              <Player />
            </ErrorBoundary>
            <Switch>
              <Route
                path={`${Routes('search')}/`}
                render={(props) => (
                  <SearchDesktopView
                    {...props}
                    redditApi={redditApi}
                  />
                )}
              />
              <Route
                path={`${Routes('playlist')}/:playlistName/`}
                render={(props) => (
                  <Playlist
                    {...props}
                    redditApi={redditApi}
                  />
                )}
              />
              <Route path={Routes('account')}>
                <Account
                  redditApi={redditApi}
                  signOut={signOut}
                />
              </Route>
              <Route
                path={`${Routes('account')}`}
                render={(props) => (
                  <SearchDesktopView
                    {...props}
                    redditApi={redditApi}
                  />
                )}
              />
              <Route path={Routes('home')}>
                <Home
                  redditApi={redditApi}
                />
              </Route>
            </Switch>
          </Main>
          <Transport
            redditApi={redditApi}
          />
        </div>
      </Router>

  );
}

export default App;
