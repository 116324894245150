import React, { useEffect, useState, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import {
  playing,
  songLength,
  volume,
  currentTrack,
  seekTo,
  activePlaylist,
  reactPlayerPosition,
} from '../RecoilAtoms/Transport';

const PlaylistDiv = styled.div`
  visibility: hidden;
  height: 100px;
  width: 100px;
  z-index: -100;
  display:none;
`;

function Player() {
  const [ready, setReady] = useState(false);
  const [time, setTime] = useState(0);
  const [timeActive, setTimeActive] = useState(false);
  const [timeStamps, setTimeStamps] = useState([]);

  const [playingState, setPlaying] = useRecoilState(playing);
  const setSongLength = useSetRecoilState(songLength);
  const volumeState = useRecoilValue(volume);
  const [seekToState, setSeekTo]: any = useRecoilState(seekTo);
  const currentTrackState: number = useRecoilValue(currentTrack);
  const setCurrentTrack = useSetRecoilState(currentTrack);
  const activePlaylistState: any = useRecoilValue(activePlaylist);
  const setReactPlayerPosition = useSetRecoilState(reactPlayerPosition);
  const reactPlayerPositionState: number = useRecoilValue(reactPlayerPosition);

  const ref: any = useRef();
  const maxNumberOfTracks: number = activePlaylistState.length;

  function nextTrack() {
    if (currentTrackState < maxNumberOfTracks - 1) {
      setCurrentTrack(currentTrackState + 1);
    } else {
      setPlaying(false);
    }
  }

  // Handles seekToState
  useEffect(() => {
    if (
      ref
      && ref.current
      && ready
      && seekToState !== undefined
      && seekToState > -1
    ) {
      ref.current.seekTo(parseInt(seekToState, 10));
      setSeekTo(-10);
    }
  }, [
    seekToState,
  ]);

  const allNumbersEqual = (arr: number[]) : boolean => arr.every((v: any) => v === arr[0]);

  const SECONDS: number = 5;
  // Checks if the track is dead and skips to the next if it is
  useEffect(() => {
    let interval: any = null;

    if (!timeActive) {
      setTimeActive(true);
    }

    if (time === 0) {
      clearInterval(interval);
      setTimeActive(false);
      setTime(SECONDS);
      if (allNumbersEqual(timeStamps)) {
        nextTrack();
        setTimeStamps([]);
      }
    }

    if (timeActive) {
      interval = setInterval(() => {
        setTime((seconds: any) => parseInt(seconds, 10) - 1);
      }, 1000);
      const temp: any = [...timeStamps];
      temp.push(reactPlayerPositionState);
      setTimeStamps(temp);
    } else if (!timeActive) {
      clearInterval(interval);
      setTimeStamps([]);
      setTime(SECONDS);
    }
    return () => clearInterval(interval);
  }, [
    currentTrackState,
    time,
  ]);

  useEffect(() => {
    setTime(SECONDS);
    setTimeStamps([]);
  }, [
    currentTrackState,
  ]);

  return (
    <PlaylistDiv>
      <ReactPlayer
        ref={ref}
        url={activePlaylistState[currentTrackState]?.url}
        playing={(ready && playingState)}
        volume={volumeState}
        onDuration={(duration: any) => setSongLength(parseInt(duration, 10))}
        onReady={(e) => setReady(!!(e))}
        onProgress={(p) => setReactPlayerPosition(p.playedSeconds)}
        onEnded={() => nextTrack()}
      />
  </PlaylistDiv>
  );
}

export default Player;
