import { useEffect, useState } from 'react';

export default function useSearchController(redditApi: any, location: any, history: any) {
  const [searchField, setSearchField] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const getSearchResults = async (searchTerm: string | null = null) => {
    const result = await redditApi.searchSubreddit(searchTerm);
    setSearchResults(result.subreddits);
  };

  useEffect(() => {
    const search = new URLSearchParams(location.search).get('search');
    setSearchField(search || '');
    if (search) {
      getSearchResults(search);
    }
  }, []);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      history.push({
        search: `?${new URLSearchParams({ search: searchField }).toString()}`,
      });
      getSearchResults(searchField);
    }, 250);
    return () => clearTimeout(timer1);
  }, [
    searchField,
  ]);

  return [
    searchField,
    setSearchField,
    searchResults,
  ] as const;
}
