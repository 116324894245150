import CryptoJS from 'crypto-js';

class RedditAPI {
  AUTH_URL: string;

  URI: string;

  CLIENT_ID: string;

  authHeaders: HeadersInit;

  expires: number | null;

  authTokenState: string | null;

  scope: string[];

  accountName: string | ((currVal: string) => string);

  authBtoa: string;

  constructor() {
    this.AUTH_URL = 'https://www.reddit.com/api/v1/access_token';
    this.URI = '';
    this.CLIENT_ID = '';
    if (window.location.href.includes('http://localhost:3000/')) {
      this.URI = 'http://localhost:3000/';
      this.CLIENT_ID = '58ixaDL4dIDL_3L_eadrWA';
    } else {
      this.URI = 'https://www.playlisterforreddit.com/';
      this.CLIENT_ID = 'QRklsIWwM2bE0SDeJNfxVQ';
    }
    this.authBtoa = window.btoa(unescape(encodeURIComponent(`${this.CLIENT_ID}:`)));
    this.authHeaders = {
      Authorization: `Basic ${this.authBtoa}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    this.expires = null;
    this.scope = ['identity', 'vote', 'read', 'history'];
    this.accountName = '';

    this.authTokenState = '';
    if (!localStorage.getItem('authTokenState')) {
      this.authTokenState = Math.random().toString(36).substring(7);
      localStorage.setItem('authTokenState', this.authTokenState);
    } else if (localStorage.getItem('authTokenState')) {
      this.authTokenState = localStorage.getItem('authTokenState');
    }
  }

  getRedditCredentials = () => {
    if (
      localStorage.getItem('at')
      && localStorage.getItem('at') !== 'undefined'
      && localStorage.getItem('expires')
      && localStorage.getItem('expires') !== 'undefined'
    ) {
      const data: string | null = localStorage.getItem('at');
      const secret: string | null = localStorage.getItem('expires');
      const bytes = CryptoJS.AES.decrypt(data!, secret!);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      const localStorageItem: string | null = localStorage.getItem('expires');
      this.expires = (localStorageItem) ? parseInt(localStorageItem, 10) : null;
      return decryptedData;
    }
    return null;
  };

  setRedditCredentials = (result: any): boolean => {
    const date: Date = new Date();
    date.setSeconds(date.getSeconds() + 3600);
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(result),
      date.getTime().toString(),
    ).toString();
    localStorage.setItem('at', encrypted);
    localStorage.setItem('expires', date.getTime().toString());
    return true;
  };

  authenticate = async () => {
    // Checks internet connection
    if (navigator.onLine === false) {
      return { error: 'You appear to be offline' };
    }

    // Check if re-auth is in process
    let res = await this.initialSetup();
    if (!('error' in res)) {
      return res;
    }

    // Check if scope has changed
    if (this.checkScopeIsSame() === false) {
      res = await this.signOut();
      return res;
    }

    // Check if authData exists
    const authData = this.getRedditCredentials();
    if (!authData) {
      res = await this.noAccountAuth();
      return res;
    }

    // Check if authData is valid
    const dateObject = new Date().getTime();
    if (
      authData
      && this.expires
      && dateObject < this.expires
    ) {
      return authData;
    }

    // Check if details have expired and refreshes if they have
    if (
      this.checkIfSignedIn()
      && authData
      && this.expires
      && dateObject > this.expires) {
      res = await this.refreshAuthenticationData();
      if (res && res.error) {
        res = this.signOut();
        return res;
      }
      return res;
    }

    // Check if no-auth details have expired and refreshes if expired
    if (
      !this.checkIfSignedIn()
      && authData
      && this.expires
      && dateObject > this.expires) {
      res = await this.noAccountAuth();
      return res;
    }

    return { error: 'unknown auth error' };
  };

  refreshAuthenticationData = async () => {
    if (!this.checkIfSignedIn()) {
      return { error: 'refreshing non-user token' };
    }
    const authData = this.getRedditCredentials();
    const fd = new FormData();
    fd.append('grant_type', 'refresh_token');
    fd.append('refresh_token', authData.refresh_token);
    const response = await fetch(
      this.AUTH_URL,
      {
        method: 'POST',
        headers: { Authorization: `Basic ${this.authBtoa}` },
        body: fd,
      },
    );
    if (response.status !== 200) {
      return { error: `refresh returned ${response.status}` };
    }
    const result = await response.json();
    if (result.error) {
      return result;
    }
    this.setRedditCredentials(result);
    return result;
  };

  checkIfSignedIn = () => {
    const res = this.getRedditCredentials();
    if (res && 'refresh_token' in res) {
      return true;
    }
    return false;
  };

  getAPIheader = () => {
    const result = this.getRedditCredentials();
    return {
      Authorization: `bearer ${result.access_token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    };
  };

  getCodeFromURL = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.code) {
      window.history.replaceState('', '', '/');
      return params.code;
    }
    return null;
  };

  initialSetup = async () => {
    const code = this.getCodeFromURL();
    if (code) {
      const params = new URLSearchParams();
      params.append('code', code);
      params.append('grant_type', 'authorization_code');
      params.append('redirect_uri', this.URI);
      const response = await fetch(
        this.AUTH_URL,
        {
          method: 'POST',
          headers: this.authHeaders,
          body: params,
        },
      );
      if (response.status !== 200) {
        return { error: `${this.AUTH_URL} returned ${response.status}` };
      }
      const result = await response.json();
      if (result.error) {
        return result;
      }
      this.setRedditCredentials(result);
      return result;
    }
    return { error: 'no code in URL' };
  };

  noAccountAuth = async () => {
    const params = new URLSearchParams();
    params.append('grant_type', 'https://oauth.reddit.com/grants/installed_client');
    params.append('device_id', 'DO_NOT_TRACK_THIS_DEVICE');
    const response = await fetch(this.AUTH_URL, {
      method: 'POST',
      body: params,
      headers: this.authHeaders,
    });
    if (response.status !== 200) {
      return { error: `${this.AUTH_URL} returned ${response.status}` };
    }
    const result = await response.json();
    if (result.error) {
      return result;
    }
    this.setRedditCredentials(result);
    return result;
  };

  signOut = async () => {
    localStorage.removeItem('authTokenState');
    localStorage.removeItem('at');
    localStorage.removeItem('expires');
    const res = await this.noAccountAuth();
    return res;
  };

  checkScopeIsSame = () => {
    const creds = this.getRedditCredentials();
    if (creds && creds.scope) {
      const res = creds.scope.split(' ').sort();
      const scopeSorted = this.scope.sort();
      if (
        (JSON.stringify(res) === JSON.stringify(scopeSorted))
        || (JSON.stringify(res) === '["*"]')
      ) {
        return true;
      }
    }
    return false;
  };

  redditDataProcess = (row: any) => (
    {
      url: row.data.url,
      title: row.data.title,
      ups: row.data.ups,
      downs: row.data.downs,
      permalink: row.data.permalink,
      thumbnail: row.data.thumbnail,
      created_utc: row.data.created_utc,
      redditId: row.data.id,
      score: row.data.score,
      likes: row.data.likes,
    }
  );

  createSubredditURL = (
    subReddit: string,
    limit: number,
    before: string,
    after: string,
    type: string,
    dateFilter: string,
  ): string => {
    let url: string = `https://oauth.reddit.com/r/${subReddit}/`;
    if (type) url = `${url}${type}/`;
    url = `${url}.json?`;
    let queryString: string = '';
    if (limit) queryString = `&limit=${limit}`;
    if (before) queryString = `${queryString}&before=${before}`;
    if (after) queryString = `${queryString}&after=${after}`;
    if (dateFilter && type === 'top') queryString = `${queryString}&t=${dateFilter}`;
    url = `${url}${queryString}`;
    return url;
  };

  parseMusicLinksFromReddit = (redditData: any) => {
    if (redditData.data.children.length === 0) {
      return [false, ''];
    }
    const lastPost = redditData.data.children.slice(-1)[0].data.name;
    const data: any = [];
    redditData.data.children.forEach((row: any) => {
      if (
        row
              && row.data
              && row.data.media
              && row.data.media.type
              && row.data.over_18 === false
              && (
                row.data.media.type.includes('soundcloud.com')
                || row.data.media.type.includes('youtube.com')
              )
      ) {
        data.push(this.redditDataProcess(row));
      }
    });
    return [data, lastPost];
  };

  getMusicLinksFromSubreddit = async (redditOptions: any) => {
    if (!await this.authenticate()) {
      return { error: 'Auth failed' };
    }
    const url = this.createSubredditURL(
      redditOptions.subReddit,
      redditOptions.limit,
      redditOptions.before,
      redditOptions.after,
      redditOptions.type,
      redditOptions.dateFilter,
    );

    const response = await fetch(
      url,
      {
        method: 'GET',
        headers: this.getAPIheader(),
      },
    );
    if (response.status !== 200) {
      return { error: `${url} returned ${response.status}` };
    }
    const result = await response.json();
    return this.parseMusicLinksFromReddit(result);
  };

  getCurrentAccount = async () => {
    const url = 'https://oauth.reddit.com/api/v1/me';
    const response = await fetch(
      url,
      {
        method: 'GET',
        headers: this.getAPIheader(),
      },
    );
    if (response.status !== 200) {
      return { error: `${url} returned ${response.status}` };
    }
    const result = await response.json();
    if (result.error) {
      return result;
    }
    this.accountName = result.name;
    return result;
  };

  getTokenURL = () => {
    if (!localStorage.getItem('authTokenState')) {
      const random = Math.random().toString(36).substring(7);
      localStorage.setItem('authTokenState', random);
    }
    let url = 'https://www.reddit.com/api/v1/authorize?';
    let queryString = `client_id=${this.CLIENT_ID}`;
    queryString = `${queryString}&response_type=code`;
    queryString = `${queryString}&state=${localStorage.getItem('authTokenState')}`;
    queryString = `${queryString}&redirect_uri=${this.URI}`;
    queryString = `${queryString}&duration=permanent`;
    queryString = `${queryString}&scope=${this.scope.join(' ')}`;
    url = `${url}${queryString}`;
    return url;
  };

  getUpvoted = async (redditOptions: any) => {
    if (!await this.authenticate() || !this.checkIfSignedIn()) {
      return { error: 'Auth failed' };
    }
    let url2: string = `https://oauth.reddit.com/user/${this.accountName}/upvoted/`;
    if (redditOptions?.after) {
      url2 = `${url2}?after=${redditOptions.after}`;
    }
    const response = await fetch(
      url2,
      {
        method: 'GET',
        headers: this.getAPIheader(),
      },
    );
    if (response.status !== 200) return false;
    const result = await response.json();
    return this.parseMusicLinksFromReddit(result);
  };

  votePost = async (dir: number, id: string) => {
    if (!await this.authenticate() || !this.checkIfSignedIn()) {
      return { error: 'Auth failed' };
    }
    const url2 = 'https://oauth.reddit.com/api/vote';
    const params = new URLSearchParams();
    params.append('dir', dir.toString());
    params.append('id', id);
    const response = await fetch(
      url2,
      {
        method: 'POST',
        headers: this.getAPIheader(),
        body: params,
      },
    );
    if (response.status !== 200) return false;
    const result = await response.json();
    return result;
  };

  searchSubreddit = async (searchTerm: string) => {
    const url2 = 'https://oauth.reddit.com/api/search_subreddits';
    const params = new URLSearchParams();
    params.append('query', searchTerm);
    const response = await fetch(
      url2,
      {
        method: 'POST',
        headers: this.getAPIheader(),
        body: params,
      },
    );
    if (response.status !== 200) return false;
    const result = await response.json();
    return result;
  };

  infoSubreddit = async (subreddit: string) => {
    const url = `https://oauth.reddit.com/r/${subreddit}/about/`;
    const response = await fetch(
      url,
      {
        method: 'GET',
        headers: this.getAPIheader(),
      },
    );
    if (response.status !== 200) return false;
    const result = await response.json();
    return result;
  };
}

export default RedditAPI;
